@font-face {
    font-family: 'fibel_nordregular';
    src: url('../assets/fonts/fibel_nord-webfont.woff2') format('woff2'), url('../assets/fonts/fibel_nord-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}


.TutorWrapperSD{
    position: absolute;
    left: 1vw;
    Top: 1vh;
    width: 90vw;
    height: 54vh;
    z-index: 2;
    overflow: hidden;
    overflow: hidden;
}

.TutorWrapperDefault{
    position: absolute;
    left: 1vw;
    Top: 1vh;
    width: 90vw;
    height: 50vh;
    z-index: 2;
    overflow: hidden;
}

.TutorPhoneFrameDefault{
    border: 1px solid rgb(255, 245, 111);
    width: 17vw;
    height: 17vw;
    border-radius: 100%;   
    transform: rotateZ(-2deg);
    margin-top: 2vh;
    margin-left: 1vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
}

.TutorPhoneFrameSD{
    border: 3px solid black;
    width: 14vw;
    height: 22vw;
    border-radius: 3%;
    background: linear-gradient(90deg, 
    rgb(41, 41, 41) 0%, 
    rgb(94, 93, 93) 3%,
    rgb(94, 93, 93) 30%,
    rgb(94, 93, 93) 50%,
    rgb(138, 137, 137) 70%,
    rgb(94, 93, 93) 98%,
    rgb(41, 41, 41) 100%);
    transform: rotateZ(-2deg);
    margin-top: 1vh;
    margin-left: 1vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-shadow: 0.1vw 0.1vw 0.5vw rgb(44, 42, 42);
    overflow: hidden;
}

.TutorPhoneScreenDefaultWait{
    width: 3vw;
    height: 3vw;
    align-self: center;
    border-radius: 100%;
    background: radial-gradient(closest-side, rgba(255, 234, 47, 0.8), rgba(255, 234, 47, 6), rgba(255, 234, 47, 0.3));
    display: flex;
    justify-content: center;
    flex-direction: column;
    z-index: 5;
    box-shadow: 0.0vw 0.0vw 3vw rgb(255, 240, 102);
    overflow: hidden;
}

.TutorPhoneScreenDefaultN{
    width: 9vw;
    height: 9vw;
    align-self: center;
    border-radius: 100%;
    border: 2px dashed yellow;
    background: radial-gradient(closest-side, rgba(255, 234, 47, 0.8), rgba(255, 234, 47, 6), rgba(255, 234, 47, 0.3));
    display: flex;
    justify-content: center;
    flex-direction: column;
    z-index: 5;
    box-shadow: 0.0vw 0.0vw 3vw rgb(255, 240, 102);
    overflow: hidden;
    
}

.TutorPhoneScreenDefaultT{
    width: 9vw;
    height: 9vw;
    align-self: center;
    border-radius: 100%;
    border: 2px dashed yellow;
    background: radial-gradient(closest-side, rgba(255, 234, 47, 0.8), rgba(255, 234, 47, 6), rgba(255, 234, 47, 0.3));
    display: flex;
    justify-content: center;
    flex-direction: column;
    z-index: 5;
    box-shadow: 0.0vw 0.0vw 3vw rgb(255, 240, 102);
    animation: Rot1 10s infinite;
    overflow: hidden;
}

.TutorPhoneScreenDefaultP{
    width: 9vw;
    height: 9vw;
    align-self: center;
    border-radius: 100%;
    border: 2px dashed rgb(0, 18, 255);
    background: radial-gradient(closest-side, rgba(255, 234, 47, 0.8), rgba(255, 234, 47, 6), rgba(255, 234, 47, 0.3));
    display: flex;
    justify-content: center;
    flex-direction: column;
    z-index: 5;
    box-shadow: 0.0vw 0.0vw 3vw rgb(102, 181, 255);
    animation: Rot1 10s infinite;
    overflow: hidden;
}

.TutorPhoneScreenDefaultLob{
    width: 9vw;
    height: 9vw;
    align-self: center;
    border-radius: 100%;
    border: 2px dashed rgb(123, 255, 0);
    background: radial-gradient(closest-side, rgba(255, 234, 47, 0.8), rgba(255, 234, 47, 6), rgba(255, 234, 47, 0.3));
    display: flex;
    justify-content: center;
    flex-direction: column;
    z-index: 5;
    box-shadow: 0.0vw 0.0vw 3vw rgb(115, 255, 102);
    animation: Rot1 10s infinite;
    overflow: hidden;
}

.TutorPhoneScreenDefaultLove{
    width: 9vw;
    height: 9vw;
    align-self: center;
    border-radius: 100%;
    border: 2px dashed rgb(255, 0, 221);
    background: radial-gradient(closest-side, rgba(255, 234, 47, 0.8), rgba(255, 234, 47, 6), rgba(255, 234, 47, 0.3));
    display: flex;
    justify-content: center;
    flex-direction: column;
    z-index: 5;
    box-shadow: 0.0vw 0.0vw 3vw rgb(250, 102, 255);
    animation: Rot1 10s infinite;
    overflow: hidden;
}

.TutorPhoneScreenDefault{
    width: 3vw;
    height: 3vw;
    align-self: center;
    border-radius: 100%;
    background: radial-gradient(closest-side, rgba(255, 234, 47, 0.8), rgba(255, 234, 47, 6), rgba(255, 234, 47, 0.3));
    display: flex;
    justify-content: center;
    flex-direction: column;
    z-index: 5;
    box-shadow: 0.0vw 0.0vw 3vw rgb(255, 240, 102);
    overflow: hidden;
}

.TutorPhoneScreenSD{
    border: 3px solid black;
    width: 95%;
    height: 85%;
    align-self: center;
    border-radius: 3%;
    background: radial-gradient(closest-side, rgb(185, 205, 212), rgb(135, 182, 201), rgb(92, 148, 170));
    display: flex;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;
}

.TutorPhoneHomeButtonDefault{
    position: absolute;
    border: 4px solid rgb(255, 224, 85);
    width: 14.5vw;
    height: 14.5vw;
    align-self: center;
    border-radius: 100%;
    box-shadow: 0.1vw 0.1vw 0.8vw rgb(255, 234, 113);
    overflow: hidden;
        
}

.TutorPhoneHomeButtonSD{
    border: 3px solid black;
    width: 2vw;
    height: 2vw;
    align-self: center;
    border-radius: 100%;
    margin-top: 0.5vw;
    background-color: rgb(68, 63, 63);
}

.Rotate1Default{
    position: absolute;
    border: 8px dashed rgb(255, 224, 85);
    width: 16.5vw;
    height: 16.5vw;
    align-self: center;
    border-radius: 100%;   
    animation: Rot1 150s infinite;     
    overflow: hidden;
}

.Rotate2Default{
    position: absolute;
    border: 6px dashed rgb(255, 224, 85);
    width: 13.8vw;
    height: 13.8vw;
    align-self: center;
    border-radius: 100%;   
    animation: Rot2 180s infinite;  
    overflow: hidden;   
}

.Rotate1SD{
    position: absolute;
    margin-left: -200vw;
    overflow: hidden;
}

.Rotate2SD{
    position: absolute;
    margin-left: -200vw;
    overflow: hidden;
}

@keyframes Rot1{
    0% {transform: rotateZ(-0deg);}
    50% {transform: rotateZ(-180deg);}
    100% {transform: rotateZ(-360deg);}
}

@keyframes Rot2{
    0% {transform: rotateZ(0deg);}
    50% {transform: rotateZ(180deg);}
    100% {transform: rotateZ(360deg);}
}

.TutorPhoneAndroid{
    width: 100%;
    height:100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.TutorPhoneAndroid.gone{
    width: 0%;
    height:0%;
    position: absolute;
    margin-left: -200vw;
}

.TutorPhone_Time{
    align-self: center;
    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-size: 3vw;
    text-shadow: 2px 2px #666666;
    color: white;
}

.TutorPhone_google{
    background-color: aliceblue;
    width: 90%;
    align-self: center;
    border-radius: 10px;
    margin-top: 7vw;
    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-size: 1vw;
    padding-left: 0.5vw;
    color: blue;
}

.TutorPhone_Apps{
    height: 3vw;
    margin-top: 3vw;
    display: flex;
    justify-content: center;
}

.TutorPhoneA1{
    background: linear-gradient(180deg, 
    rgb(144, 250, 102) 0%, 
    rgb(170, 252, 138) 20%, 
    rgb(144, 250, 102) 40%, 
    rgb(73, 126, 52) 100%);
    width: 2vw;
    height: 2vw;
    border-radius: 0.5vw;
    margin-top: 0.5vw;
    box-shadow: 0.1vw 0.1vw 0.2vw rgb(44, 42, 42);
}

.TutorPhoneA2{
    background: linear-gradient(180deg, 
    rgb(224, 224, 224) 0%, 
    rgb(255, 255, 255) 20%, 
    rgb(224, 224, 224) 40%, 
    rgb(156, 156, 156) 100%);
    width: 2vw;
    height: 2vw;
    border-radius: 0.5vw;
    margin-top: 0.5vw;
    margin-left: 0.5vw;
    box-shadow: 0.1vw 0.1vw 0.2vw rgb(44, 42, 42);
}

.TutorPhoneA3{
    background: linear-gradient(180deg, 
    rgb(224, 224, 224) 0%, 
    rgb(255, 255, 255) 20%, 
    rgb(224, 224, 224) 40%, 
    rgb(156, 156, 156) 100%);
    width: 2vw;
    height: 2vw;
    border-radius: 0.5vw;
    margin-top: 0.5vw;
    margin-left: 0.5vw;
    box-shadow: 0.1vw 0.1vw 0.2vw rgb(44, 42, 42);
}

.TutorPhoneA4{
    background: linear-gradient(180deg, 
    rgb(102, 191, 250) 0%, 
    rgb(149, 211, 253) 20%, 
    rgb(102, 191, 250) 40%, 
    rgb(49, 95, 126) 100%);
    width: 2vw;
    height: 2vw;
    border-radius: 0.5vw;
    margin-top: 0.5vw;
    margin-left: 0.5vw;
    box-shadow: 0.1vw 0.1vw 0.2vw rgb(44, 42, 42);
}

.SprechblaseWrapper{
    position: absolute;
    display: flex;
    width: 87%;
    height: auto;
    top: 2vh;
    left: 10vw;
    margin-bottom: 1vh;
    overflow: hidden;
}

.SprechblaseWrapper.gone{
    position: absolute;
    margin-top:-200vh;
}

.SprechblaseBlase{
    
    width: 90%;
    margin-left: 9%;
    padding-left: 5%;
    height:auto+1vh;
    padding-top: 1%;
    padding-bottom: 2%;
    text-align: center;
    background-color: white;
    border-radius: 1vw;
    box-shadow: 0.1vw 0.1vw 0.5vw rgb(44, 42, 42);
    font-family: 'fibel_nordregular', sans-serif;
    font-size: 3vw;
    padding-top: 1vw;
    z-index: 1;
}

.SprechblaseNase{
    background-color: white;
    width: 5.9vw;
    height: 7vw;
    position: absolute;
    z-index: 0;
    transform: rotateZ(40deg);
    top:2.5vw;
    left:4.5vw;
    z-index: 2;
    margin-bottom: 3vh;
}

.SingDroidN{
        width: 100%;
        height: 100%;
        background-image: url('../assets/SingDroid/SingDroidN.png');
        background-size: contain;
        background-repeat: no-repeat;
        align-self: center;
        justify-self: center;   
        margin-top: 54%;
}

.SingDroidTalk{
    width: 100%;
    height: 100%;
    background-image: url('../assets/SingDroid/SingDroidTalk.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;   
    margin-top: 54%;
}

.SingDroidLob{
    width: 100%;
    height: 100%;
    background-image: url('../assets/SingDroid/SingDroidLob1.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;   
    margin-top: 54%;
}

.SingDroidLove{
    width: 100%;
    height: 100%;
    background-image: url('../assets/SingDroid/SingDroidL.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;   
    margin-top: 54%;
}

.SingDroidPoint{
    width: 100%;
    height: 100%;
    background-image: url('../assets/SingDroid/SingDroidT2.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;   
    margin-top: 54%;
}

.SingDroidThink{
    width: 100%;
    height: 100%;
    background-image: url('../assets/SingDroid/SingDroidT.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;   
    margin-top: 54%;
}

.SingDroidOh{
    width: 100%;
    height: 100%;
    background-image: url('../assets/SingDroid/SingDroidO.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;   
    margin-top: 54%;
}

.SingDroidDoubt{
    width: 100%;
    height: 100%;
    background-image: url('../assets/SingDroid/SingDroidDoubt.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;   
    margin-top: 54%;
}

.SingDroidStopIt{
    width: 100%;
    height: 100%;
    background-image: url('../assets/SingDroid/SingDroidStop.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;   
    margin-top: 54%;
}

.SingDroid.gone{
    position: absolute;
}