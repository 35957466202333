.SIL_TutImg1{
    width: 90%;
    height: 99%;
    background-image: url('../assets/targetimg/Bild3_Fisch_wrong.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 0vw;
    overflow: hidden;
}

.SIL_TutImg2{
    width: 90%;
    height: 99%;
    background-image: url('../assets/targetimg/Bild3_Fisch_korrekt.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 0vw;
    overflow: hidden;
    
}

.SIL_TutImg3{
    width: 90%;
    height: 99%;
    background-image: url('../assets/targetimg/Bild3_Fisch_wrong3.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 0vw;
    overflow: hidden;
}

.SIL_Img1_1a{
    width: 90%;
    height: 99%;
    background-image: url('../assets/targetimg/SIL11a.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 0vw;
    overflow: hidden;
}

.SIL_Img1_1b{
    width: 90%;
    height: 99%;
    background-image: url('../assets/targetimg/SIL11b.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 0vw;
    overflow: hidden;
}

.SIL_Img1_1c{
    width: 90%;
    height: 99%;
    background-image: url('../assets/targetimg/SIL11c.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 0vw;
    overflow: hidden;
}

.SIL_Img1_2a{
    width: 90%;
    height: 99%;
    background-image: url('../assets/targetimg/SIL12a.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 0vw;
    overflow: hidden;
}

.SIL_Img1_2b{
    width: 90%;
    height: 99%;
    background-image: url('../assets/targetimg/SIL12b.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 0vw;
    overflow: hidden;
}

.SIL_Img1_2c{
    width: 90%;
    height: 99%;
    background-image: url('../assets/targetimg/SIL12c.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 0vw;
    overflow: hidden;
}

.SIL_Img1_3a{
    width: 90%;
    height: 99%;
    background-image: url('../assets/targetimg/SIL13a.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 0vw;
    overflow: hidden;
}

.SIL_Img1_3b{
    width: 90%;
    height: 99%;
    background-image: url('../assets/targetimg/SIL13b.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 0vw;
    overflow: hidden;
}

.SIL_Img1_3c{
    width: 90%;
    height: 99%;
    background-image: url('../assets/targetimg/SIL13c.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 0vw;
    overflow: hidden;
}

.SIL_Img1_4a{
    width: 90%;
    height: 99%;
    background-image: url('../assets/targetimg/SIL14a.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 0vw;
    overflow: hidden;
}

.SIL_Img1_4b{
    width: 90%;
    height: 99%;
    background-image: url('../assets/targetimg/SIL14b.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 0vw;
    overflow: hidden;
}

.SIL_Img1_4c{
    width: 90%;
    height: 99%;
    background-image: url('../assets/targetimg/SIL14c.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 0vw;
    overflow: hidden;
}

.SIL_Img1_5a{
    width: 90%;
    height: 99%;
    background-image: url('../assets/targetimg/SIL15a.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 0vw;
    overflow: hidden;
}

.SIL_Img1_5b{
    width: 90%;
    height: 99%;
    background-image: url('../assets/targetimg/SIL15b.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 0vw;
    overflow: hidden;
}

.SIL_Img1_5c{
    width: 90%;
    height: 99%;
    background-image: url('../assets/targetimg/SIL15c.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 0vw;
    overflow: hidden;
}

.SIL_Img1_6a{
    width: 90%;
    height: 99%;
    background-image: url('../assets/targetimg/SIL16a.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 0vw;
    overflow: hidden;
}

.SIL_Img1_6b{
    width: 90%;
    height: 99%;
    background-image: url('../assets/targetimg/SIL16b.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 0vw;
    overflow: hidden;
}

.SIL_Img1_6c{
    width: 90%;
    height: 99%;
    background-image: url('../assets/targetimg/SIL16c.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 0vw;
    overflow: hidden;
}

.SIL_Img2_1a{
    width: 90%;
    height: 99%;
    background-image: url('../assets/targetimg/SIL21a.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 0vw;
    overflow: hidden;
}

.SIL_Img2_1b{
    width: 90%;
    height: 99%;
    background-image: url('../assets/targetimg/SIL21b.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 0vw;
    overflow: hidden;
}

.SIL_Img2_1c{
    width: 90%;
    height: 99%;
    background-image: url('../assets/targetimg/SIL21c.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 0vw;
    overflow: hidden;
}

.SIL_Img2_2a{
    width: 90%;
    height: 99%;
    background-image: url('../assets/targetimg/SIL22a.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 0vw;
    overflow: hidden;
    
}

.SIL_Img2_2b{
    width: 90%;
    height: 99%;
    background-image: url('../assets/targetimg/SIL22b.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 0vw;
    overflow: hidden;
}

.SIL_Img2_2c{
    width: 90%;
    height: 99%;
    background-image: url('../assets/targetimg/SIL22c.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 0vw;
    overflow: hidden;
}

.SIL_Img2_3a{
    width: 90%;
    height: 99%;
    background-image: url('../assets/targetimg/SIL23a.png');
    background-size: contain;
    background-repeat: no-repeat;
    color: white;
 
    overflow: hidden;
}

.SIL_Img2_3b{
    width: 90%;
    height: 99%;
    background-image: url('../assets/targetimg/SIL23b.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 0vw;
    overflow: hidden;
}

.SIL_Img2_3c{
    width: 90%;
    height: 99%;
    background-image: url('../assets/targetimg/SIL23c.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 0vw;
    overflow: hidden;
}

.SIL_Img2_4a{
    width: 90%;
    height: 99%;
    background-image: url('../assets/targetimg/SIL24a.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 0vw;
    overflow: hidden;
}

.SIL_Img2_4b{
    width: 90%;
    height: 99%;
    background-image: url('../assets/targetimg/SIL24b.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 0vw;
    overflow: hidden;
}

.SIL_Img2_4c{
    width: 90%;
    height: 99%;
    background-image: url('../assets/targetimg/SIL24c.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 0vw;
    overflow: hidden;
}

.SIL_Img2_5a{
    width: 90%;
    height: 99%;
    background-image: url('../assets/targetimg/SIL25a.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 0vw;
    overflow: hidden;
}

.SIL_Img2_5b{
    width: 90%;
    height: 99%;
    background-image: url('../assets/targetimg/SIL25b.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 0vw;
    overflow: hidden;
}

.SIL_Img2_5c{
    width: 90%;
    height: 99%;
    background-image: url('../assets/targetimg/SIL25c.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 0vw;
    overflow: hidden;
}

.SIL_Img2_6a{
    width: 90%;
    height: 99%;
    background-image: url('../assets/targetimg/SIL26a.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 0vw;
    overflow: hidden;
}

.SIL_Img2_6b{
    width: 90%;
    height: 99%;
    background-image: url('../assets/targetimg/SIL26b.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 0vw;
    overflow: hidden;
}

.SIL_Img2_6c{
    width: 90%;
    height: 99%;
    background-image: url('../assets/targetimg/SIL26c.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 0vw;
    overflow: hidden;
}

.SIL_Img3_1a{
    width: 90%;
    height: 99%;
    background-image: url('../assets/targetimg/SIL31a.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 0vw;
    overflow: hidden;
}

.SIL_Img3_1b{
    width: 90%;
    height: 99%;
    background-image: url('../assets/targetimg/SIL31b.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 0vw;
    overflow: hidden;
}

.SIL_Img3_1c{
    width: 90%;
    height: 99%;
    background-image: url('../assets/targetimg/SIL31c.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 0vw;
    overflow: hidden;
}

.SIL_Img3_2a{
    width: 90%;
    height: 99%;
    background-image: url('../assets/targetimg/SIL32a.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 0vw;
    overflow: hidden;
}

.SIL_Img3_2b{
    width: 90%;
    height: 99%;
    background-image: url('../assets/targetimg/SIL32b.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 0vw;
    overflow: hidden;
}

.SIL_Img3_2c{
    width: 90%;
    height: 99%;
    background-image: url('../assets/targetimg/SIL32c.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 0vw;
    overflow: hidden;
}

.SIL_Img3_3a{
    width: 90%;
    height: 99%;
    background-image: url('../assets/targetimg/SIL33a.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 1vw;
    overflow: hidden;
}

.SIL_Img3_3b{
    width: 90%;
    height: 99%;
    background-image: url('../assets/targetimg/SIL33b.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 1vw;
    overflow: hidden;
}

.SIL_Img3_3c{
    width: 90%;
    height: 99%;
    background-image: url('../assets/targetimg/SIL33c.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 1vw;
    overflow: hidden;
}

.SIL_Img3_4a{
    width: 90%;
    height: 99%;
    background-image: url('../assets/targetimg/SIL34a.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 1vw;
    overflow: hidden;
}

.SIL_Img3_4b{
    width: 90%;
    height: 99%;
    background-image: url('../assets/targetimg/SIL34b.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 1vw;
    overflow: hidden;
}

.SIL_Img3_4c{
    width: 90%;
    height: 99%;
    background-image: url('../assets/targetimg/SIL34c.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 1vw;
    overflow: hidden;
}

.SIL_Img3_5a{
    width: 90%;
    height: 99%;
    background-image: url('../assets/targetimg/SIL35a.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 1vw;
    overflow: hidden;
}

.SIL_Img3_5b{
    width: 90%;
    height: 99%;
    background-image: url('../assets/targetimg/SIL35b.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 1vw;
    overflow: hidden;
}

.SIL_Img3_5c{
    width: 90%;
    height: 99%;
    background-image: url('../assets/targetimg/SIL35c.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 1vw;
    overflow: hidden;
}

.SIL_Img3_6a{
    width: 90%;
    height: 99%;
    background-image: url('../assets/targetimg/SIL36a.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 1vw;
    overflow: hidden;
}

.SIL_Img3_6b{
    width: 90%;
    height: 99%;
    background-image: url('../assets/targetimg/SIL36b.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 1vw;
    overflow: hidden;
}

.SIL_Img3_6c{
    width: 90%;
    height: 99%;
    background-image: url('../assets/targetimg/SIL36c.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 1vw;
    overflow: hidden;
}


.SIL_Img5_1a{
    width: 90%;
    height: 99%;
    background-image: url('../assets/targetimg/SIL51a.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 1vw;
    overflow: hidden;
}

.SIL_Img5_1b{
    width: 90%;
    height: 99%;
    background-image: url('../assets/targetimg/SIL51b.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 1vw;
    overflow: hidden;
}

.SIL_Img5_1c{
    width: 90%;
    height: 99%;
    background-image: url('../assets/targetimg/SIL51c.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 1vw;
    overflow: hidden;
}

.SIL_Img5_2a{
    width: 90%;
    height: 99%;
    background-image: url('../assets/targetimg/SIL52a.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 1vw;
    overflow: hidden;
}

.SIL_Img5_2b{
    width: 90%;
    height: 99%;
    background-image: url('../assets/targetimg/SIL52b.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 1vw;
    overflow: hidden;
}

.SIL_Img5_2c{
    width: 90%;
    height: 99%;
    background-image: url('../assets/targetimg/SIL52c.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 1vw;
    overflow: hidden;
}

.SIL_Img5_3a{
    width: 90%;
    height: 99%;
    background-image: url('../assets/targetimg/SIL53a.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 1vw;
    overflow: hidden;
}

.SIL_Img5_3b{
    width: 90%;
    height: 99%;
    background-image: url('../assets/targetimg/SIL53b.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 1vw;
    overflow: hidden;
}

.SIL_Img5_3c{
    width: 90%;
    height: 99%;
    background-image: url('../assets/targetimg/SIL53c.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 1vw;
    overflow: hidden;
}

.SIL_Img5_4a{
    width: 90%;
    height: 99%;
    background-image: url('../assets/targetimg/SIL54a.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 1vw;
    overflow: hidden;
}

.SIL_Img5_4b{
    width: 90%;
    height: 99%;
    background-image: url('../assets/targetimg/SIL54b.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 1vw;
    overflow: hidden;
}

.SIL_Img5_4c{
    width: 90%;
    height: 99%;
    background-image: url('../assets/targetimg/SIL54c.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 1vw;
    overflow: hidden;
}

.SIL_Img5_5a{
    width: 90%;
    height: 99%;
    background-image: url('../assets/targetimg/SIL55a.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 1vw;
    overflow: hidden;
}

.SIL_Img5_5b{
    width: 90%;
    height: 99%;
    background-image: url('../assets/targetimg/SIL55b.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 1vw;
    overflow: hidden;
}

.SIL_Img5_5c{
    width: 90%;
    height: 99%;
    background-image: url('../assets/targetimg/SIL55c.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 1vw;
    overflow: hidden;
}

.SIL_Img5_6a{
    width: 90%;
    height: 99%;
    background-image: url('../assets/targetimg/SIL56a.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 1vw;
    overflow: hidden;
}

.SIL_Img5_6b{
    width: 90%;
    height: 99%;
    background-image: url('../assets/targetimg/SIL56b.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 1vw;
    overflow: hidden;
}

.SIL_Img5_6c{
    width: 90%;
    height: 99%;
    background-image: url('../assets/targetimg/SIL56c.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 1vw;
    overflow: hidden;
}

.SIL_Img6_1a{
    width: 90%;
    height: 99%;
    background-image: url('../assets/targetimg/SIL61a.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 1vw;
    overflow: hidden;
}

.SIL_Img6_1b{
    width: 90%;
    height: 99%;
    background-image: url('../assets/targetimg/SIL61b.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 1vw;
    overflow: hidden;
}

.SIL_Img6_1c{
    width: 90%;
    height: 99%;
    background-image: url('../assets/targetimg/SIL61c.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 1vw;
    overflow: hidden;
}

.SIL_Img6_2a{
    width: 90%;
    height: 99%;
    background-image: url('../assets/targetimg/SIL62a.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 1vw;
    overflow: hidden;
}

.SIL_Img6_2b{
    width: 90%;
    height: 99%;
    background-image: url('../assets/targetimg/SIL62b.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 1vw;
    overflow: hidden;
}

.SIL_Img6_2c{
    width: 90%;
    height: 99%;
    background-image: url('../assets/targetimg/SIL62c.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 1vw;
    overflow: hidden;
}

.SIL_Img6_3a{
    width: 90%;
    height: 99%;
    background-image: url('../assets/targetimg/SIL63a.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 1vw;
    overflow: hidden;
}

.SIL_Img6_3b{
    width: 90%;
    height: 99%;
    background-image: url('../assets/targetimg/SIL63b.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 1vw;
    overflow: hidden;
}

.SIL_Img6_3c{
    width: 90%;
    height: 99%;
    background-image: url('../assets/targetimg/SIL63c.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 1vw;
    overflow: hidden;
}

.SIL_Img6_4a{
    width: 90%;
    height: 99%;
    background-image: url('../assets/targetimg/SIL64a.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 1vw;
    overflow: hidden;
}

.SIL_Img6_4b{
    width: 90%;
    height: 99%;
    background-image: url('../assets/targetimg/SIL64b.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 1vw;
    overflow: hidden;
}

.SIL_Img6_4c{
    width: 90%;
    height: 99%;
    background-image: url('../assets/targetimg/SIL64c.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 1vw;
    overflow: hidden;
}

.SIL_Img6_5a{
    width: 90%;
    height: 99%;
    background-image: url('../assets/targetimg/SIL65a.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 1vw;
    overflow: hidden;
}

.SIL_Img6_5b{
    width: 90%;
    height: 99%;
    background-image: url('../assets/targetimg/SIL65b.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 1vw;
    overflow: hidden;
}

.SIL_Img6_5c{
    width: 90%;
    height: 99%;
    background-image: url('../assets/targetimg/SIL65c.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 1vw;
    overflow: hidden;
}

.SIL_Img6_6a{
    width: 90%;
    height: 99%;
    background-image: url('../assets/targetimg/SIL66a.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 1vw;
    overflow: hidden;
}

.SIL_Img6_6b{
    width: 90%;
    height: 99%;
    background-image: url('../assets/targetimg/SIL66b.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 1vw;
    overflow: hidden;
}

.SIL_Img6_6c{
    width: 90%;
    height: 99%;
    background-image: url('../assets/targetimg/SIL66c.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 1vw;
    overflow: hidden;
}

.SIL_Img7_1a{
    width: 90%;
    height: 99%;
    background-image: url('../assets/targetimg/SIL71a.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 1vw;
    overflow: hidden;
}

.SIL_Img7_1b{
    width: 90%;
    height: 99%;
    background-image: url('../assets/targetimg/SIL71b.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 1vw;
    overflow: hidden;
}

.SIL_Img7_1c{
    width: 90%;
    height: 99%;
    background-image: url('../assets/targetimg/SIL71c.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 1vw;
    overflow: hidden;
}

.SIL_Img7_2a{
    width: 90%;
    height: 99%;
    background-image: url('../assets/targetimg/SIL72a.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 1vw;
    overflow: hidden;
}

.SIL_Img7_2b{
    width: 90%;
    height: 99%;
    background-image: url('../assets/targetimg/SIL72b.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 1vw;
    overflow: hidden;
}

.SIL_Img7_2c{
    width: 90%;
    height: 99%;
    background-image: url('../assets/targetimg/SIL72c.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 1vw;
    overflow: hidden;
}

.SIL_Img7_3a{
    width: 90%;
    height: 99%;
    background-image: url('../assets/targetimg/SIL73a.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 1vw;
    overflow: hidden;
}

.SIL_Img7_3b{
    width: 90%;
    height: 99%;
    background-image: url('../assets/targetimg/SIL73b.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 1vw;
    overflow: hidden;
}

.SIL_Img7_3c{
    width: 90%;
    height: 99%;
    background-image: url('../assets/targetimg/SIL73c.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 1vw;
    overflow: hidden;
}

.SIL_Img7_4a{
    width: 90%;
    height: 99%;
    background-image: url('../assets/targetimg/SIL74a.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 1vw;
    overflow: hidden;
}

.SIL_Img7_4b{
    width: 90%;
    height: 99%;
    background-image: url('../assets/targetimg/SIL74b.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 1vw;
    overflow: hidden;
}

.SIL_Img7_4c{
    width: 90%;
    height: 99%;
    background-image: url('../assets/targetimg/SIL74c.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 1vw;
    overflow: hidden;
}

.SIL_Img7_5a{
    width: 90%;
    height: 99%;
    background-image: url('../assets/targetimg/SIL75a.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 1vw;
    overflow: hidden;
}

.SIL_Img7_5b{
    width: 90%;
    height: 99%;
    background-image: url('../assets/targetimg/SIL75b.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 1vw;
    overflow: hidden;
}

.SIL_Img7_5c{
    width: 90%;
    height: 99%;
    background-image: url('../assets/targetimg/SIL75c.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 1vw;
    overflow: hidden;
}

