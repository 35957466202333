@font-face {
    font-family: 'fibel_nordregular';
    src: url('../assets/fonts/fibel_nord-webfont.woff2') format('woff2'), url('../assets/fonts/fibel_nord-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: 'comic sans';
    src: url('../assets/fonts/comic-sans.woff') format('woff');
}

@font-face {
    font-family: 'dancing script';
    src: url('../assets/fonts/DancingScript_Regular.woff') format('woff');
}

.StimWrapperDefault{
    width: 70%;
    height: 55%;
    align-self: center;
    background: linear-gradient(180deg, 
    rgb(224, 224, 224) 0%, 
    rgb(255, 255, 255) 10%, 
    rgb(224, 224, 224) 99%, 
    rgb(156, 156, 156) 100%);
    box-shadow: 0.1vw 0.1vw 2vw rgb(255, 255, 46);
    display: flex;
    justify-content: center;
    overflow: hidden;
}

.StimWrapperSD{
   
    width: 70%;
    height: 55%;
    align-self: center;
    background: linear-gradient(180deg, 
    rgb(224, 224, 224) 0%, 
    rgb(255, 255, 255) 10%, 
    rgb(224, 224, 224) 99%, 
    rgb(156, 156, 156) 100%);
    box-shadow: 0.1vw 0.1vw 2vw rgb(44, 42, 42);
    display: flex;
    justify-content: center;
    overflow: hidden;
}

.StimulusTextType1
{
    align-self: center;
    text-align: center;
    width: 99%;
    height: auto;
    font-family: 'fibel_nordregular', sans-serif;
    font-size: 7vw;
    overflow: hidden;
}

.StimulusTextType2
{
    align-self: center;
    text-align: center;
    width: 99%;
    height: auto;
    font-family: 'Times New Roman', Times, serif;
    font-size: 5vw;
    overflow: hidden;
}

.StimulusTextType4
{
    align-self: center;
    text-align: center;
    width: 99%;
    height: auto;
    font-family: 'fibel_nordregular', sans-serif;
    font-size: 3.5vw;
    overflow: hidden;
}

.StimulusTextType5
{
    align-self: center;
    text-align: center;
    width: 99%;
    height: auto;
    font-family: 'comic sans', sans-serif;
    font-size: 4.5vw;
    overflow: hidden;
}

.StimulusTextType6
{
    align-self: center;
    text-align: center;
    width: 99%;
    height: auto;
    font-family: 'dancing script', sans-serif;
    font-size: 4.5vw;
    overflow: hidden;
}