.ButtonFrame{
    
    width: 99%;
    height: 25vh;
    margin-left: 1%;
    margin-top: 1%;
    overflow: hidden;

}

.Button.InaktivSD{
    align-self: center;
    width: 100%;
    height: 95%;
    border: 5px solid black;
    background: linear-gradient(180deg, 
    rgb(65, 61, 61) 0%,
    rgb(110, 104, 104) 5%, 
    rgb(110, 104, 104) 30%,
    rgb(148, 139, 139) 42%,
    rgb(110, 104, 104) 70%,
    rgb(110, 104, 104) 95%,
    rgb(65, 61, 61) 100%);
    box-shadow: 0.1vw 0.1vw 0.5vw rgb(44, 42, 42);
    transition: 1s;
}
.Button.InaktivDefault{
    align-self: center;
    width: 100%;
    height: 95%;
    border: 3px solid rgb(112, 255, 93);
    border-radius: 15px;
    background: linear-gradient(180deg, 
    rgba(65, 61, 61, 0.1) 0%,
    rgba(110, 104, 104, 0.2) 5%, 
    rgba(110, 104, 104, 0.7) 30%,
    rgba(148, 139, 139, 0.7) 42%,
    rgba(110, 104, 104, 0.7) 70%,
    rgba(110, 104, 104, 0.2) 95%,
    rgba(65, 61, 61, 0.1) 100%);
    box-shadow: 0.1vw 0.1vw 0.5vw rgb(44, 42, 42);
    transition: 1s;
}

.Icon.Inaktiv{
    width: 100%;
    height: 95%;
    background-image: url('../assets/Button_inaktiv.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;

}

.Button.SameSD{
    align-self: center;
    border: 5px solid black;
    width: 100%;
    height: 95%;
    background: linear-gradient(180deg, 
    rgb(18, 71, 7) 0%,
    rgb(53, 207, 22) 2%,
    rgb(53, 207, 22) 30%,
    rgb(61, 230, 28) 40%,
    rgb(53, 207, 22) 56%,
    rgb(53, 207, 22) 98%,
    rgb(18, 71, 7) 100%);
    box-shadow: 0.1vw 0.1vw 0.5vw rgb(44, 42, 42);
    transition: 1s;
    margin-bottom: 1vh;
    
}

.Button.SameDefault{
    align-self: center;
    width: 100%;
    height: 95;
    border: 3px solid rgb(112, 255, 93);
    border-radius: 15px;
    background: linear-gradient(180deg, 
    rgba(18, 71, 7, 0.1) 0%,
    rgba(53, 207, 22, 0.2) 2%,
    rgba(53, 207, 22, 0.7) 30%,
    rgba(61, 230, 28, 0.7) 40%,
    rgba(53, 207, 22, 0.7) 56%,
    rgba(53, 207, 22,0.2) 98%,
    rgba(18, 71, 7, 0.1) 100%);
    overflow: hidden;
    transition: 1s;
    margin-bottom: 1vh;
}

.Icon.Same{
    width: 100%;
    height: 95%;
    background-image: url('../assets/Button_ja.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
}

.Button.DifferentSD{
    align-self: center;
    width: 100%;
    height: 95%;
    border: 5px solid black;
    background: linear-gradient(180deg, 
    rgb(71, 7, 7) 0%,
    rgb(207, 22, 22) 2%,
    rgb(207, 22, 22) 30%,
    rgb(230, 28, 28) 40%,
    rgb(207, 22, 22) 56%,
    rgb(207, 22, 22) 98%,
    rgb(71, 7, 7) 100%);
    box-shadow: 0.1vw 0.1vw 0.5vw rgb(44, 42, 42);
    transition: 1s;
    overflow: hidden;
}

.Button.DifferentDefault{
    align-self: center;
    width: 100%;
    height: 95%;
    border: 3px solid rgb(255, 93, 93);
    border-radius: 15px;
    background: linear-gradient(180deg, 
    rgba(71, 7, 7, 0.1) 0%,
    rgba(207, 22, 22, 0.2) 2%,
    rgba(207, 22, 22, 0.7) 30%,
    rgba(230, 28, 28, 0.7) 40%,
    rgba(207, 22, 22, 0.7) 56%,
    rgba(207, 22, 22, 0.2) 98%,
    rgba(71, 7, 7, 0.1) 100%);
    transition: 1s;
    overflow: hidden;
}

.Icon.Different{
    width: 100%;
    height: 95%;
    background-image: url('../assets/Button_nein.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    overflow: hidden;
}