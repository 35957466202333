@font-face {
    font-family: 'fibel_nordregular';
    src: url('../assets/fonts/fibel_nord-webfont.woff2') format('woff2'), url('../assets/fonts/fibel_nord-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'comic sans';
    src: url('../assets/fonts/comic-sans.woff') format('woff');
}

@font-face {
    font-family: 'dancing script';
    src: url('../assets/fonts/DancingScript_Regular.woff') format('woff');
}


.TargetWrapperSD{
    align-self: center;
    width: 75%;
    height: 72vh;
    margin-top: 15vh;
    justify-content: center;
    display: flex;
    overflow-y: hidden;
    padding-top: 1vh; 
    overflow: hidden;
}

.TargetWrapperDefault{
    align-self: center;
    width: 80%;
    height: 80vh;
    margin-top: 0vh;
    justify-content: center;
    display: flex;
    overflow-y: hidden;
    padding-top: 2vh; 
    overflow: hidden;
    overflow: hidden;
}

.TargetWrapper.gone{
    
    position: absolute;
    margin-left: 300vw;
    overflow: hidden;
    
}

.TargetFrameSD{
    border: 4px solid black;
    width: 80%;
    height: 95%;
    display: flex;
    justify-content: center;
    background: linear-gradient(180deg, 
    rgb(110, 108, 73) 0%, 
    rgb(170, 166, 114) 2%,
    rgb(206, 201, 130) 20%,
    rgb(170, 166, 114) 98%,
    rgb(124, 121, 84) 100%);
    box-shadow: 0.1vw 0.1vw 0.5vw rgb(44, 42, 42);
    transform: rotateZ(1deg);
    display: flex;
    flex-direction: column;  
    overflow: hidden; 
}

.TargetFrameDefault{
    width: 40vw;
    height: 40vw;
    display: flex;
    justify-content: center;
    border-radius: 100%;
    display: flex;
    flex-direction: column;  
    overflow: hidden;
}

@keyframes TFrameRotate{
    0% {transform: rotateZ(0deg);}
    50% {transform: rotateZ(180deg);}
    100% {transform: rotateZ(360deg);}
}


.UpperTitelSD{
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 6vw;
    text-align: center;
    margin-top: -7vh;
    color: rgb(78, 11, 11);
    text-shadow: 2px 2px #666666;
    z-index: 2;
    overflow: hidden;
}

.UpperTitelDefault{
    position: absolute;
    margin-left: -200vw;
}

.RotatorDefault{
    position: absolute;
    z-index: 5;
    color: white;
    font-size: 10vw;
    width: 40vw;
    height: 40vw;
    display: flex;
    border-radius: 100%;
    animation: TFrameRotate 150s infinite; 
    border: 8px dashed rgb(252, 252, 115);
    overflow: hidden;
}

.RotatorSD{
    position: absolute;
    margin-left: -200vw;
    overflow: hidden;
}

.LowerTitelDefault{
    position: absolute;
    margin-left: -200vw;
    overflow: hidden;
}

.TapeDefault{
    position: absolute;
    margin-left: -200vw;
    overflow: hidden;
}

.WantedWindowDefault{
    width: 35vw;
    height: 35vw;
    border-radius: 100%;
    background: radial-gradient(closest-side, rgb(255, 255, 255), rgb(255, 255, 255), rgb(255, 255, 255), rgba(170, 165, 92, 0));
    border: 2px solid rgb(252, 252, 115);
    box-shadow: 0.1vw 0.1vw 1vw rgb(241, 255, 114);
    align-self: center;
    overflow: hidden;
}


.WantedWindowSD{
    border: 3px solid black;
    width: 90%;
    height: 75%;
    margin-top:-6%;
    background-color: white;
    align-self: center;
    overflow: hidden;
}

.LowerTitelSD{
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 4vw;
    text-align: center;
    color: rgb(110, 45, 45);
    text-shadow: 2px 2px #666666;
    margin-top: 1vh;
    overflow: hidden;
}

.TapeSD{
    position: absolute;
    border: 3px solid black;
    border: 4px solid black;
    width: 16vw;
    height: 3vw;
    background-color: rgba(116, 110, 91, 0.664);
    left: 43vw;
    top:20vh;
    transform: rotateZ(-4deg);
    overflow: hidden;
}

.TargetFeld{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    overflow: hidden;
}

.TargetType1{
    text-align: center;
    font-family: 'fibel_nordregular', sans-serif;
    font-size: 8vw;
    color:black;
    align-self: center;
    background-color: white;
    overflow: hidden;
}

.TargetType2{
    text-align: center;
    font-size: 5.5vw;
    color:black;
    color:black;
    align-self: center;
    font-family: 'Times New Roman', Times, serif;
    background-color: white;
    overflow: hidden;
}

.TargetType5{
    text-align: center;
    font-size: 6.5vw;
    color:black;
    color:black;
    align-self: center;
    font-family: 'comic sans', sans-serif;
    background-color: white;
    overflow: hidden;
}

.TargetType6{
    text-align: center;
    font-size: 6.5vw;
    color:black;
    color:black;
    align-self: center;
    font-family: 'dancing script', sans-serif;
    background-color: white;
    overflow: hidden;
}

.TargetType3.Maus{
    width: 100%;
    height: 100%;
    background-image: url('../assets/targetimg/Maus1.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 10vw;
    overflow: hidden;
}

.TargetType3.Nase{
    width: 100%;
    height: 100%;
    background-image: url('../assets/targetimg/Nase.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 10vw;
    overflow: hidden;
}

.TargetType3.Boden{
    width: 100%;
    height: 100%;
    background-image: url('../assets/targetimg/Boden.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 10vw;
    overflow: hidden;
}

.TargetType3.Denken{
    width: 100%;
    height: 100%;
    background-image: url('../assets/targetimg/Denken.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 10vw;
    overflow: hidden;
}

.TargetType3.Finden{
    width: 100%;
    height: 100%;
    background-image: url('../assets/targetimg/Finden.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 10vw;
    overflow: hidden;
}

.TargetType3.Entenerpel{
    width: 100%;
    height: 100%;
    background-image: url('../assets/targetimg/Entenerpel.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 10vw;
    overflow: hidden;
}

.TargetType3.Feigenkerne{
    width: 100%;
    height: 100%;
    background-image: url('../assets/targetimg/Feigenkerne.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 10vw;
    overflow: hidden;
}

.TargetType3.Verbogen{
    width: 100%;
    height: 100%;
    background-image: url('../assets/targetimg/verbogen.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 10vw;
    overflow: hidden;
}

.TargetType3.Gelikt{
    width: 100%;
    height: 100%;
    background-image: url('../assets/targetimg/Like.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 10vw;
    overflow: hidden;
}

.TargetType3.Besen{
    width: 100%;
    height: 100%;
    background-image: url('../assets/targetimg/Besen.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 10vw;
    overflow: hidden;
}

.TargetType3.Farbe{
    width: 100%;
    height: 100%;
    background-image: url('../assets/targetimg/Farbe.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 10vw;
    overflow: hidden;
}

.TargetType3.fege{
    width: 100%;
    height: 100%;
    background-image: url('../assets/targetimg/fege.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 10vw;
    overflow: hidden;
}

.TargetType3.badet{
    width: 100%;
    height: 100%;
    background-image: url('../assets/targetimg/badet.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 10vw;
    overflow: hidden;
}

.TargetType3.Pudelbeine{
    width: 100%;
    height: 100%;
    background-image: url('../assets/targetimg/Pudelbeine.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 10vw;
    overflow: hidden;
}

.TargetType3.Notenhefte{
    width: 100%;
    height: 100%;
    background-image: url('../assets/targetimg/Notenhefte.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 10vw;
    overflow: hidden;
}

.TargetType3.gelogen{
    width: 100%;
    height: 100%;
    background-image: url('../assets/targetimg/gelogen.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 10vw;
    overflow: hidden;
}

.TargetType3.downloaden{
    width: 100%;
    height: 100%;
    background-image: url('../assets/targetimg/downloaden.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 10vw;
    overflow: hidden;
}
.TargetType3.Entenküken{
    width: 100%;
    height: 100%;
    background-image: url('../assets/targetimg/Entenkuken2.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 10vw;
    overflow: hidden;
}
.TargetType3.gehoben{
    width: 100%;
    height: 100%;
    background-image: url('../assets/targetimg/gehoben.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 10vw;
    overflow: hidden;
}

.TargetType3.Hose{
    width: 100%;
    height: 100%;
    background-image: url('../assets/targetimg/Hose.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 10vw;
    overflow: hidden;
}

.TargetType3.Gabel{
    width: 100%;
    height: 100%;
    background-image: url('../assets/targetimg/Gabel.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 10vw;
    overflow: hidden;
}

.TargetType3.tanzen{
    width: 100%;
    height: 100%;
    background-image: url('../assets/targetimg/tanzen.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 10vw;
    overflow: hidden;
}

.TargetType3.reden{
    width: 100%;
    height: 100%;
    background-image: url('../assets/targetimg/reden.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 10vw;
    overflow: hidden;
}

.TargetType3.Vortänzer{
    width: 100%;
    height: 100%;
    background-image: url('../assets/targetimg/Vortanzer.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 10vw;
    overflow: hidden;
    font-size: 0%;
}

.TargetType3.Verkäufer{
    width: 100%;
    height: 100%;
    background-image: url('../assets/targetimg/Verkaufer.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 10vw;
    overflow: hidden;
}

.TargetType3.verbunden{
    width: 100%;
    height: 100%;
    background-image: url('../assets/targetimg/verbunden.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 10vw;
    overflow: hidden;
}

.TargetType3.ausgetrunken{
    width: 100%;
    height: 100%;
    background-image: url('../assets/targetimg/ausgetrunken.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 10vw;
    overflow: hidden;
}

.TargetType3.Möbellager{
    width: 100%;
    height: 100%;
    background-image: url('../assets/targetimg/mobellager2.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 10vw;
    overflow: hidden;
}

.TargetType3.Golfspielen{
    width: 100%;
    height: 100%;
    background-image: url('../assets/targetimg/Golfspielen.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 10vw;
    overflow: hidden;
}

.TargetType3.Comicbuch{
    width: 100%;
    height: 100%;
    background-image: url('../assets/targetimg/Comicbuch.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 10vw;
    overflow: hidden;
}

.TargetType3.Rindersteak{
    width: 100%;
    height: 100%;
    background-image: url('../assets/targetimg/Rindersteak.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 10vw;
    overflow: hidden;
}

.TargetType3.gingen{
    width: 100%;
    height: 100%;
    background-image: url('../assets/targetimg/gingen.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 10vw;
    overflow: hidden;
}

.TargetType3.trugen{
    width: 100%;
    height: 100%;
    background-image: url('../assets/targetimg/trugen.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 10vw;
    overflow: hidden;
}

.TargetType3.Gänse{
    width: 100%;
    height: 100%;
    background-image: url('../assets/targetimg/gaense.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 10vw;
    overflow: hidden;
}

.TargetType3.Bäume{
    width: 100%;
    height: 100%;
    background-image: url('../assets/targetimg/baeume.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 10vw;
    overflow: hidden;
}

.TargetType3.Regenwolke{
    width: 100%;
    height: 100%;
    background-image: url('../assets/targetimg/Regenwolke.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 10vw;
    overflow: hidden;
}

.TargetType3.Brotdose{
    width: 100%;
    height: 100%;
    background-image: url('../assets/targetimg/Brotdose.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 10vw;
    overflow: hidden;
}

.TargetType3.Hasenhaus{
    width: 100%;
    height: 100%;
    background-image: url('../assets/targetimg/Hasenhaus.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 10vw;
    overflow: hidden;
}

.TargetType3.Korb{
    width: 100%;
    height: 100%;
    background-image: url('../assets/targetimg/Korb.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 10vw;
    overflow: hidden;
}

.TargetType3.Hemd{
    width: 100%;
    height: 100%;
    background-image: url('../assets/targetimg/Hemd.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 10vw;
    overflow: hidden;
}

.TargetType3.Brett{
    width: 100%;
    height: 100%;
    background-image: url('../assets/targetimg/Brett.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 10vw;
    overflow: hidden;
}

.TargetType3.Nuss{
    width: 100%;
    height: 100%;
    background-image: url('../assets/targetimg/Nuss.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    margin-left: 10vw;
    overflow: hidden;
}









