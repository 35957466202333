* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: Candara, sans-serif;
    overscroll-behavior-x: none;

}

a{
    text-decoration: none;
    color:black;
}
a:link{
    text-decoration: none;
    color:black;
}


.MainTitel{
    text-decoration: none;
}

.navigationsLeiste {
    
    background-image: linear-gradient(to right, #A4C8A4 10%, #A4C8A4 10%, rgb(17, 17, 17) 25%);
    position:absolute;
    height: 6vh;
    display:flex;
    top:0;
    position: sticky;
    align-items:left;
    justify-content:left;
    z-index:999;
}

.navigationsBereichPC {
    display: flex;
    height: 99%;
    align-content:center;
}

.buttonBereich {
    display: flex;
    height: 100%;
    align-content: center;
}


.navigationsElement {
    list-style-type: none;
    display:flex;
    margin-left: 1vw;
    font-size: 1.5vw;
    font-weight: 800;
    padding: 0.8vw;
}

.navigationsElement.gone {
    font-size: 0;
    position: absolute;
    margin-left: -500vw;
}



.loginFeldFeld {
    list-style-type: none;
    display:flex;
    margin-left: 1vw;
    font-size: 1.1vw;
    font-weight: 800;
    padding: 0.0vw;
}

.navigationsElement:hover{
    background: white;
    height:100%;
    color:black;
}

.navigationsLink {
    text-decoration: none;
    color: #eaeaea;
}

    .navigationsLink:hover {
        color: #A4C8A4;
    }

.LoginBereich{
    width: 50%;
    background: red;
    padding: 5px;
    display:flex;
    justify-content:center;
    align-content:center;
    margin-left:5vw;
}

.Footer {
    background: #A4C8A4;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.FooterSpaltenBereich{
    display:flex;
    width: 100%;
    justify-content:center;
}

.FooterSpalte{
    display:flex;
    flex-direction:column;
    margin:2vh 3vw 3vh 2vw;
    text-align:center;
}

.FooterCopyrightBereich{
    width:100%;
    height: 10%;
    display:flex;
    justify-content:center;
   
}

.Copyright{
    font-size:1vw;
    color:white;
}


.FooterLink{
    text-decoration:none;
    color:black;
    font-size: 1vw;
    font-weight: 500;
}

.FooterLink:hover{
    color: white;
    font-weight: 800;
}

.FillerLinie {
    width: 100%;
    height: 5vh;
    background: #A4C8A4;
}

.LoginFeldWrapper{
    
    height: 4vh;
    width: 20vw;
    display: flex;
    justify-content: center;
    align-content: center;
    margin-top: 1vh;
}

.loginFeld.offline{
 box-sizing: border-box;
 outline: none;
 border: solid 2px #005526;
 border-radius: 4px;
 color: #292929;
 width: 100%;
 padding: 12px;
 font-size: vw;
 background: rgba(255, 255, 255, 1);
}

.loginFeld.online{
    width: 0;
    height: 0;
    border:none;
}

.loginPwdFeld.online{
    width: 0;
    height: 0;
    border:none;
}


.RegistWrapper{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
}

.RegistTitel{
    font-size: 3vw;
    margin-top: 10vh;
    text-align: center;
    align-self: center;
}

.RegistContainer{
    background-color: rgb(229, 248, 238);
    width: 40vw;
    height: 40vh;
    align-self: center;
    margin-top: 3vh;
    display: flex;
    margin-bottom: 5vh;

}

.RegistContainer.gone{
    background-color: rgb(229, 248, 238);
    position: absolute;
    left: -500vw;
    width: 0;
    height: 0;
    align-self: center;
    margin-top: 3vh;
    display: flex;
    margin-bottom: 5vh;

}

.RegSpalte{
    margin-top: 3vh;
    margin-left: 1vw;
    width: 28%;
    display: flex;
    flex-direction: column;
}

.RegInput{
    border:none;
    font-size: 3vh;
    height:5vh;
    margin-bottom: 3vh;
    border-radius: 0;
}

.RegInput:hover{
    background-color: #A4C8A4;
}

.RegLabel{
    font-size: 1.5vh;
    text-align: right;
    padding: 0.5vh;
    color:dimgrey;
}

.Regbtn{
    width: 100%;
 min-width: 50px;
 color: #565656;
 padding: 2px;
 font-size: 20px;
 font-weight: bold;
 border: solid 2px #005526;;
 border-radius: 4px;
 background: rgb(184, 255, 169);
 cursor: pointer;
}

.Regbtn:hover{
    background: rgb(109, 252, 80);
}

.Impressum{
    width: 100vw;
    height: 150vh;
    align-content: center;
    display: flex;
    flex-direction: column;
    background: rgb(17, 17, 17);
    color:white

}

.Imp_Wrapper{
    align-self: center;
    width: 80vw;
}

.Imp_Titel{
    text-align: center;
}

.Imp_Textblock{
    margin-top: 1vh;
}

.Imp_Haftung1{
    margin-top: 1vh;
    margin-bottom: 1vh;

}

.UserNameField{
    color: white;
    font-size: 1.2vw;
    position: absolute;
    margin-left: 90%;
    margin-top: 0.5%;
}

.AuswertungbuttonBereich{
    display: flex;
}

.Auswertungsbutton{
    border: 1px solid black;
    margin-top: 2vh;
    bottom: 1vh;
    margin-left: 1vw;
    padding: 1%;
    width: 20vw;
    font-size: 2vw;
    border: 3px solid rgb(0, 0, 0);
    border-radius: 15px;
    background: linear-gradient(180deg, 
    rgba(18, 71, 7, 0.1) 0%,
    rgba(53, 207, 22, 0.2) 2%,
    rgba(53, 207, 22, 0.7) 30%,
    rgba(61, 230, 28, 0.7) 40%,
    rgba(53, 207, 22, 0.7) 56%,
    rgba(53, 207, 22,0.2) 98%,
    rgba(18, 71, 7, 0.1) 100%);
    overflow: hidden;
}

.AuswertungsbuttonText{
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 110%;
    margin-left: 2%;
    width: 25vw;
    background-color:  #A4C8A4;
    border-radius: 20px;
    border: none;
    text-align: center;
    transition: 1s;
    overflow: hidden;
}

.AuswertungsbuttonText:hover{
    background-color:  #2ecf2e;
    cursor: pointer;
    transition: 1s;
    overflow: hidden;
}

.MultiAuswertungsbutton{
    border: 1px solid black;
    margin-top: 2vh;
    padding: 1%;
    bottom: 1vh;
    margin-left: 28vw;
    width: 20vw;
    font-size: 2vw;
    border: 3px solid rgb(0, 0, 0);
    border-radius: 15px;
    background: linear-gradient(180deg, 
    rgba(18, 71, 7, 0.1) 0%,
    rgba(53, 207, 22, 0.2) 2%,
    rgba(53, 207, 22, 0.7) 30%,
    rgba(61, 230, 28, 0.7) 40%,
    rgba(53, 207, 22, 0.7) 56%,
    rgba(53, 207, 22,0.2) 98%,
    rgba(18, 71, 7, 0.1) 100%);
    overflow: hidden;
}

.AuswertungsIcon:hover{
    font-size: 0vw;
    border: 1px solid rgb(91, 87, 87);
    width: 1.5vw;
    height: 1.5vw;
    background-color: bisque;
    cursor: pointer;
    background-image: none;
}

.Auswertungsbutton:hover{
    background: linear-gradient(180deg, 
    rgba(18, 71, 7, 0.3) 0%,
    rgba(53, 207, 22, 0.6) 2%,
    rgba(53, 207, 22, 0.9) 30%,
    rgba(61, 230, 28, 1.0) 40%,
    rgba(53, 207, 22, 0.9) 56%,
    rgba(53, 207, 22,0.6) 98%,
    rgba(18, 71, 7, 0.3) 100%);
    cursor: pointer;
}

.MultiAuswertungsbutton:hover{
    background: linear-gradient(180deg, 
    rgba(18, 71, 7, 0.3) 0%,
    rgba(53, 207, 22, 0.6) 2%,
    rgba(53, 207, 22, 0.9) 30%,
    rgba(61, 230, 28, 1.0) 40%,
    rgba(53, 207, 22, 0.9) 56%,
    rgba(53, 207, 22,0.6) 98%,
    rgba(18, 71, 7, 0.3) 100%);
    cursor: pointer;
}

.MultiAuswertungsbutton2:hover{
    background: linear-gradient(180deg, 
    rgba(18, 71, 7, 0.3) 0%,
    rgba(53, 207, 22, 0.6) 2%,
    rgba(53, 207, 22, 0.9) 30%,
    rgba(61, 230, 28, 1.0) 40%,
    rgba(53, 207, 22, 0.9) 56%,
    rgba(53, 207, 22,0.6) 98%,
    rgba(18, 71, 7, 0.3) 100%);
    cursor: pointer;
}

.Auswertungsbutton.block{
    background: linear-gradient(180deg, 
    rgba(71, 7, 7, 0.3) 0%,
    rgba(207, 22, 22, 0.6) 2%,
    rgba(207, 22, 22, 0.9) 30%,
    rgb(230, 28, 28) 40%,
    rgba(207, 22, 22, 0.9) 56%,
    rgba(207, 22, 22, 0.6) 98%,
    rgba(71, 7, 7, 0.3) 100%);
    cursor: pointer;
}

.MultiAuswertungsbutton2{
    border: 1px solid black;
    margin-top: 2vh;
    padding: 1%;
    bottom: 1vh;
    margin-left: 8vw;
    width: 20vw;
    font-size: 2vw;
    border: 3px solid rgb(0, 0, 0);
    border-radius: 15px;
    background: linear-gradient(180deg, 
    rgba(18, 71, 7, 0.1) 0%,
    rgba(53, 207, 22, 0.2) 2%,
    rgba(53, 207, 22, 0.7) 30%,
    rgba(61, 230, 28, 0.7) 40%,
    rgba(53, 207, 22, 0.7) 56%,
    rgba(53, 207, 22,0.2) 98%,
    rgba(18, 71, 7, 0.1) 100%);
    overflow: hidden;
    text-align: center;
}






