@font-face {
    font-family: 'fibel_nordregular';
    src: url('../assets/fonts/fibel_nord-webfont.woff2') format('woff2'), url('../assets/fonts/fibel_nord-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: 'comic sans';
    src: url('../assets/fonts/comic-sans.woff') format('woff');
}

@font-face {
    font-family: 'dancing script';
    src: url('../assets/fonts/DancingScript_Regular.woff') format('woff');
}

.TargetKleinWrapper{
   
    width: 100vw;
    height: 80%;
    display:flex;
    flex-direction: column;
    align-content: center;
    overflow: hidden;

}

.LupeKleinGlasSD{
    border: 14px solid black;
    border-radius: 100%;
    width: 15vw;
    height:15vw;
    align-self: center;
    z-index: 5;
    background: radial-gradient(closest-side, rgba(185, 205, 212, 0.720), rgba(135, 182, 201, 0.720), rgba(92, 148, 170, 0.720));
    display: flex;
    justify-content: center;
    align-content: center;
    overflow: hidden;
    
}

.LupeKleinGlasDefault{
    border: 3px solid rgb(255, 242, 55);
    border-radius: 100%;
    width: 13vw;
    height:13vw;
    align-self: center;
    z-index: 5;
    background: radial-gradient(closest-side, rgba(255, 255, 255, 0.72), rgba(255, 255, 255, 0.720), rgba(255, 255, 236, 0.720), rgba(92, 148, 170, 0));
    display: flex;
    justify-content: center;
    align-content: center;
    overflow: hidden;
    
}

.LupeKleinStielSD{
    border: 3px solid black;
    width: 2vw;
    align-self: center;
    margin-top: -1vh;
    height: 25vh;
    background: linear-gradient(90deg, 
    rgb(39, 23, 14) 0%, 
    rgb(77, 45, 27) 30%,
    rgb(77, 45, 27) 50%,
    rgb(121, 74, 48) 70%,
    rgb(77, 45, 27) 100%);
    overflow: hidden;
}

.LupeKleinStielDefault{
    width: 2vw;
    align-self: center;
    margin-top: -0.5vh;
    height: 25vh;
    background: linear-gradient(90deg, 
    rgba(126, 124, 16, 0.1) 0%,
    rgba(207, 204, 22, 0.3) 4%,
    rgba(207, 204, 22, 0.6) 29%,
    rgba(235, 231, 23, 0.6) 45%,
    rgba(207, 204, 22, 0.6) 56%,
    rgba(207, 204, 22, 0.3) 95%,
    rgba(100, 99, 11, 0.1) 100%);
    overflow: hidden;
}

.TargetTypeKlein1{
    text-align: center;
    font-family: 'fibel_nordregular', sans-serif;
    font-size: 1vw;
    align-self: center;
    overflow: hidden;
}

.TargetTypeKlein2{
    text-align: center;
    font-size: 0.8vw;
    align-self: center;
    font-family: 'Times New Roman', Times, serif;
    overflow: hidden;
}

.TargetTypeKlein5{
    text-align: center;
    font-size: 0.8vw;
    align-self: center;
    font-family: 'comic sans', sans-serif;
    overflow: hidden;
}

.TargetTypeKlein6{
    text-align: center;
    font-size: 0.8vw;
    align-self: center;
    font-family: 'dancing script', sans-serif;
    overflow: hidden;
}

.TargetTypeKlein3.Maus{
    width: 100%;
    height: 100%;
    background-image: url('../assets/targetimg/Maus1.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    font-size: 0vw;
    overflow: hidden;
}

.TargetTypeKlein3.Nase{
    width: 100%;
    height: 100%;
    background-image: url('../assets/targetimg/Nase.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    font-size: 0vw;
    overflow: hidden;
}

.TargetTypeKlein3.Boden{
    width: 100%;
    height: 100%;
    background-image: url('../assets/targetimg/Boden.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    font-size: 0vw;
    overflow: hidden;
}

.TargetTypeKlein3.Denken{
    width: 100%;
    height: 100%;
    background-image: url('../assets/targetimg/Denken.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    font-size: 0vw;
    overflow: hidden;
}

.TargetTypeKlein3.Finden{
    width: 100%;
    height: 100%;
    background-image: url('../assets/targetimg/Finden.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    font-size: 0vw;
    overflow: hidden;
}

.TargetTypeKlein3.Entenerpel{
    width: 100%;
    height: 100%;
    background-image: url('../assets/targetimg/Entenerpel.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    overflow: hidden;
    font-size: 0vw;
}

.TargetTypeKlein3.Feigenkerne{
    width: 100%;
    height: 100%;
    background-image: url('../assets/targetimg/Feigenkerne.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    font-size: 0vw;
    overflow: hidden;
}

.TargetTypeKlein3.Verbogen{
    width: 100%;
    height: 100%;
    background-image: url('../assets/targetimg/verbogen.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    font-size: 0vw;
    overflow: hidden;
}

.TargetTypeKlein3.Gelikt{
    width: 100%;
    height: 100%;
    background-image: url('../assets/targetimg/Like.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    overflow: hidden;
    font-size: 0vw;
}

.TargetTypeKlein3.Besen{
    width: 100%;
    height: 100%;
    background-image: url('../assets/targetimg/Besen.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    overflow: hidden;
    font-size: 0vw;
}

.TargetTypeKlein3.Farbe{
    width: 100%;
    height: 100%;
    background-image: url('../assets/targetimg/Farbe.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    overflow: hidden;
}

.TargetTypeKlein3.fege{
    width: 100%;
    height: 100%;
    background-image: url('../assets/targetimg/fege.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    overflow: hidden;
    font-size: 0vw;
}

.TargetTypeKlein3.badet{
    width: 100%;
    height: 100%;
    background-image: url('../assets/targetimg/badet.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    overflow: hidden;
    font-size: 0vw;
}

.TargetTypeKlein3.Pudelbeine{
    width: 100%;
    height: 100%;
    background-image: url('../assets/targetimg/Pudelbeine.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    overflow: hidden;
    font-size: 0vw;
}

.TargetTypeKlein3.Notenhefte{
    width: 100%;
    height: 100%;
    background-image: url('../assets/targetimg/Notenhefte.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    overflow: hidden;
    font-size: 0vw;
}

.TargetTypeKlein3.gelogen{
    width: 100%;
    height: 100%;
    background-image: url('../assets/targetimg/gelogen.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    overflow: hidden;
    font-size: 0vw;
}

.TargetTypeKlein3.downloaden{
    width: 100%;
    height: 100%;
    background-image: url('../assets/targetimg/downloaden.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    overflow: hidden;
    font-size: 0vw;
}
.TargetTypeKlein3.Entenküken{
    width: 100%;
    height: 100%;
    background-image: url('../assets/targetimg/Entenkuken2.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    overflow: hidden;
    font-size: 0vw;
}
.TargetTypeKlein3.gehoben{
    width: 100%;
    height: 100%;
    background-image: url('../assets/targetimg/gehoben.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    overflow: hidden;
    font-size: 0vw;
}

.TargetTypeKlein3.Hose{
    width: 100%;
    height: 100%;
    background-image: url('../assets/targetimg/Hose.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    overflow: hidden;
    font-size: 0vw;
}

.TargetTypeKlein3.Gabel{
    width: 100%;
    height: 100%;
    background-image: url('../assets/targetimg/Gabel.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    overflow: hidden;
    font-size: 0vw;
}

.TargetTypeKlein3.reden{
    width: 100%;
    height: 100%;
    background-image: url('../assets/targetimg/reden.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    overflow: hidden;
    font-size: 0vw;
}

.TargetTypeKlein3.tanzen{
    width: 100%;
    height: 100%;
    background-image: url('../assets/targetimg/tanzen.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    overflow: hidden;
    font-size: 0vw;
}

.TargetTypeKlein3.Vortänzer{
    width: 100%;
    height: 100%;
    background-image: url('../assets/targetimg/Vortanzer.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    overflow: hidden;
    font-size: 0vw;
    margin-top:5vh;
}

.TargetTypeKlein3.Verkäufer{
    width: 100%;
    height: 100%;
    background-image: url('../assets/targetimg/Verkaufer.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    overflow: hidden;
    font-size: 0vw;
    margin-top:5vh;
}

.TargetTypeKlein3.verbunden{
    width: 100%;
    height: 100%;
    background-image: url('../assets/targetimg/verbunden.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    overflow: hidden;
    font-size: 0vw;
    margin-top:5vh;
}

.TargetTypeKlein3.ausgetrunken{
    width: 100%;
    height: 100%;
    background-image: url('../assets/targetimg/ausgetrunken.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    overflow: hidden;
    font-size: 0vw;
    margin-top:5vh;
}

.TargetTypeKlein3.Möbellager{
    width: 100%;
    height: 100%;
    background-image: url('../assets/targetimg/mobellager2.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    overflow: hidden;
    font-size: 0vw;
    margin-top:5vh;
}

.TargetTypeKlein3.Golfspielen{
    width: 100%;
    height: 100%;
    background-image: url('../assets/targetimg/Golfspielen.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    overflow: hidden;
    font-size: 0vw;
    margin-top:5vh;
}

.TargetTypeKlein3.Comicbuch{
    width: 100%;
    height: 100%;
    background-image: url('../assets/targetimg/Comicbuch.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    overflow: hidden;
    font-size: 0vw;
    margin-top:5vh;
}

.TargetTypeKlein3.Rindersteak{
    width: 100%;
    height: 100%;
    background-image: url('../assets/targetimg/Rindersteak.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    overflow: hidden;
    font-size: 0vw;
    margin-top:5vh;
}

.TargetTypeKlein3.gingen{
    width: 100%;
    height: 100%;
    background-image: url('../assets/targetimg/gingen.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    overflow: hidden;
    font-size: 0vw;
    margin-top:5vh;
}

.TargetTypeKlein3.trugen{
    width: 100%;
    height: 100%;
    background-image: url('../assets/targetimg/trugen.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    overflow: hidden;
    font-size: 0vw;
    margin-top:5vh;
}

.TargetTypeKlein3.Gänse{
    width: 100%;
    height: 100%;
    background-image: url('../assets/targetimg/gaense.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    overflow: hidden;
    font-size: 0vw;
    margin-top:5vh;
}

.TargetTypeKlein3.Bäume{
    width: 100%;
    height: 100%;
    background-image: url('../assets/targetimg/baeume.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    overflow: hidden;
    font-size: 0vw;
    margin-top:5vh;
}

.TargetTypeKlein3.Regenwolke{
    width: 100%;
    height: 100%;
    background-image: url('../assets/targetimg/Regenwolke.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    overflow: hidden;
    font-size: 0vw;
    margin-top:5vh;
}

.TargetTypeKlein3.Brotdose{
    width: 100%;
    height: 100%;
    background-image: url('../assets/targetimg/Brotdose.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    overflow: hidden;
    font-size: 0vw;
    margin-top:5vh;
}

.TargetTypeKlein3.Hasenhaus{
    width: 100%;
    height: 100%;
    background-image: url('../assets/targetimg/Hasenhaus.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    overflow: hidden;
    font-size: 0vw;
    margin-top:5vh;
}

.TargetTypeKlein3.Korb{
    width: 100%;
    height: 100%;
    background-image: url('../assets/targetimg/Korb.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    overflow: hidden;
    font-size: 0vw;
    margin-top:5vh;
}

.TargetTypeKlein3.Hemd{
    width: 100%;
    height: 100%;
    background-image: url('../assets/targetimg/Hemd.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    overflow: hidden;
    font-size: 0vw;
    margin-top:5vh;
}

.TargetTypeKlein3.Brett{
    width: 100%;
    height: 100%;
    background-image: url('../assets/targetimg/Brett.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    overflow: hidden;
    font-size: 0vw;
    margin-top:5vh;
}

.TargetTypeKlein3.Nuss{
    width: 100%;
    height: 100%;
    background-image: url('../assets/targetimg/Nuss.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    color: white;
    overflow: hidden;
    font-size: 0vw;
    margin-top:5vh;
}