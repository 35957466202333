

.WorldWrapper{
    width: 100%;
    height: 100vh;
    display: flex;
    overflow-y: hidden;
    overflow-x: hidden;
    
}


.TischplatteSD{
    position: fixed;
    width: 100%;
    height: 100vh;
    Top:0;
    Left:0;
    background-color: rgb(97, 76, 49);
    background: linear-gradient(-1deg, 
    rgb(97, 76, 49) 0%,rgb(98, 77, 50) 4%,rgb(97, 76, 49) 9%,
    rgb(43, 33, 21) 10%, rgb(97, 76, 49) 11%,rgb(97, 76, 49) 24%,rgb(43, 33, 21) 25%, 
    rgb(97, 76, 49) 26%,rgb(97, 76, 49) 34%,rgb(43, 33, 21) 35%, rgb(97, 76, 49) 36%,
    rgb(97, 76, 49) 50%,rgb(43, 33, 21) 51%, rgb(97, 76, 49) 52%,rgb(97, 76, 49) 63%,
    rgb(43, 33, 21) 64%, rgb(97, 76, 49) 65%,rgb(97, 76, 49) 70%,rgb(43, 33, 21) 71%, 
    rgb(97, 76, 49) 72%,rgb(97, 76, 49) 83%, rgb(43, 33, 21) 84%, rgb(97, 76, 49) 85%,
    rgb(97, 76, 49) 91%,rgb(43, 33, 21) 92%, rgb(97, 76, 49) 93%,rgb(97, 76, 49)  100%);
    overflow: hidden;
}

.TischplatteDefault{
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-color: rgb(75, 109, 93);
    overflow: hidden;
}

.DatenPapierDefault{
    position: absolute;
    left:25vw;
    top: 14vh;
    width: 50vw;
    height: 80vh;
    overflow: hidden;  
}

.DatenPapierSD{
    position: absolute;
    left:25vw;
    top: 14vh;
    width: 50vw;
    height: 80vh;
    background: linear-gradient(0deg, 
    rgba(241,235,245,1) 0%, 
    rgba(241,235,245,1) 10%,
    rgb(255, 255, 255) 50%,
    rgba(241,235,245,1) 82%,
    rgba(241,235,245,1) 100%);
    box-shadow: 0.1vw 0.1vw 0.5vw rgb(44, 42, 42);
    border: 4px solid black;    
    overflow: hidden;
}

.DatenPapier.breit{
    position: absolute;
    left:10vw;
    top: 14vh;
    width: 70vw;
    height: 80vh;
    background: linear-gradient(0deg, 
    rgba(241,235,245,1) 0%, 
    rgba(241,235,245,1) 10%,
    rgb(255, 255, 255) 50%,
    rgba(241,235,245,1) 82%,
    rgba(241,235,245,1) 100%);
    box-shadow: 0.1vw 0.1vw 0.5vw rgb(44, 42, 42);
    border: 4px solid black; 
    transition: 1s;   
    overflow: hidden;
}

.Tape1{
    position: absolute;
    border: 4px solid black;
    width: 12vw;
    height: 3vw;
    background-color: rgba(116, 110, 91, 0.664);
    left: 22vw;
    top:14vh;
    transform: rotateZ(-40deg);
    overflow: hidden;
}

.Tape2{
    position: absolute;
    border: 4px solid black;
    width: 13vw;
    height: 3vw;
    background-color: rgba(116, 110, 91, 0.664);
    left: 66vw;
    top:15vh;
    transform: rotateZ(40deg);
    overflow: hidden;
}

.Tape3{
    position: absolute;
    border: 4px solid black;
    width: 13vw;
    height: 3vw;
    background-color: rgba(116, 110, 91, 0.664);
    left: 66vw;
    top:89vh;
    transform: rotateZ(160deg);
    overflow: hidden;
}

.Tape4{
    position: absolute;
    border: 4px solid rgb(0, 0, 0);
    width: 16vw;
    height: 3vw;
    background-color: rgba(116, 110, 91, 0.664);
    left: 21vw;
    top:89vh;
    transform: rotateZ(10deg);
    overflow: hidden;
}

.Tape5{
    position: absolute;
    border: 4px solid black;
    width: 4vw;
    height: 2vw;
    background-color: rgba(116, 110, 91, 0.664);
    left: 96vw;
    top:17vh;
    transform: rotateZ(42deg);
    transition: 2s;
    overflow: hidden;
}

.Tape6{
    position: absolute;
    border: 4px solid black;
    width: 8vw;
    height: 2vw;
    background-color: rgba(116, 110, 91, 0.664);
    left: 88vw;
    top:5vh;
    transform: rotateZ(18deg);
    transition: 2s;
    overflow: hidden;
}

.duckpic1{
    position: absolute;
    border: 4px solid black;
    background: linear-gradient(0deg, 
    rgba(241,235,245,1) 0%, 
    rgba(241,235,245,1) 10%,
    rgb(255, 255, 255) 50%,
    rgba(241,235,245,1) 82%,
    rgba(241,235,245,1) 100%);
    box-shadow: 0.1vw 0.1vw 0.5vw rgb(44, 42, 42);
    width: 14vw;   
    height: 14vw; 
    left:81vw;
    top: 5vh;
    transform: rotateZ(26deg);
    display: flex;
    justify-content: center;
    transition: 2s;
    overflow: hidden;
}

.duckpic1Default{
    position: absolute;
    border: 4px solid rgb(255, 228, 77);    
    box-shadow: 0.1vw 0.1vw 0.5vw rgb(44, 42, 42);
    width: 22vw;   
    height: 4vw; 
    left: 76vw;
    top: 8vh;
    display: flex;
    justify-content: center;
    transition: 2s;
    overflow: hidden;
}

.duckpic2{
    position: absolute;
    border: 4px solid black;
    background: linear-gradient(0deg, 
    rgba(241,235,245,1) 0%, 
    rgba(241,235,245,1) 10%,
    rgb(255, 255, 255) 50%,
    rgba(241,235,245,1) 82%,
    rgba(241,235,245,1) 100%);
    box-shadow: 0.1vw 0.1vw 0.5vw rgb(44, 42, 42);
    width: 14vw;   
    height: 14vw; 
    left:86vw;
    top: 15vh;
    transform: rotateZ(38deg);
    display: flex;
    justify-content: center;
    transition: 2s;
    overflow: hidden;
    
}

.DuckPicFrameSD{
    border: 2px solid black;
    margin-top: 1vh;
    width: 90%;
    height: 70%;
    overflow: hidden;
}

.DuckPicFrameDefault{
    border: 2px dashed rgba(236, 255, 68, 0.685);
    margin-top: 1vh;
    width: 95%;
    height: 70%;
    overflow: hidden;
}

.DekoLupeWrapper{
    position: absolute;
    width: 20vw;
    height: 49vh;
    left: 5vw;
    top: 45vh;
    display:flex;
    flex-direction: column;
    align-content: center;
    transform: rotateZ(20deg);
    transition: 2s;
    overflow: hidden;
}

.DekoLupeWrapper.gone{
    position: absolute;
    margin-left: -300vw;
    overflow: hidden;
}

.DLGlas{
    border: 14px solid black;
    border-radius: 100%;
    width: 14vw;
    height:14vw;
    align-self: center;
    z-index: 5;
    background: radial-gradient(closest-side, rgba(185, 205, 212, 0.336), rgba(135, 182, 201, 0.336), rgba(92, 148, 170, 0.336));
    overflow: hidden;
}

.DLStiel{
    border: 3px solid black;
    width: 12%;
    align-self: center;
    margin-top: -1vh;
    height: 60%;
    background: linear-gradient(90deg, 
    rgb(39, 23, 14) 0%, 
    rgb(77, 45, 27) 30%,
    rgb(77, 45, 27) 50%,
    rgb(121, 74, 48) 70%,
    rgb(77, 45, 27) 100%);
    overflow: hidden;
}

.DekoSmartPhoneWrapper{
    position: absolute;
    width: 20vw;
    height: 48vh;
    top: -3vh;
    left: 2vw;
    display: flex;
    justify-content: center;
    transform: rotateZ(-20deg);
    transition: 3s;
    overflow: hidden;
}

.DSMP_FrameSD{
    border: 3px solid black;
    height: 22vw;
    width: 13vw;
    align-self: center;
    border-radius: 14px;
    background: linear-gradient(90deg, 
    rgb(41, 41, 41) 0%, 
    rgb(94, 93, 93) 3%,
    rgb(94, 93, 93) 30%,
    rgb(94, 93, 93) 50%,
    rgb(138, 137, 137) 70%,
    rgb(94, 93, 93) 98%,
    rgb(41, 41, 41) 100%);
    display: flex;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;
}

.DSMP_FrameDefault{
    border: 1px solid rgb(255, 245, 111);
    width: 17vw;
    height: 17vw;
    border-radius: 100%;   
    margin-top: 5.8vh;
    margin-left: 0vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-shadow: 0.0vw 0.0vw 0.3vw rgb(255, 252, 75);
    overflow: hidden;
}

.DSMPScreenDefault{
    position: absolute;
    margin-left: -200vw;
    overflow: hidden;
}

.DSMPScreenSD{
    border: 2px solid black;
    align-self: center;
    height: 18vw;
    width: 11vw;
    border-radius: 14px;
    background: radial-gradient(closest-side, rgb(185, 205, 212), rgb(135, 182, 201), rgb(92, 148, 170));
    display: flex;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;
}

.DSMPHomeButtonDefault{
    position: absolute;
    border: 2px solid rgb(255, 230, 0);
    width: 13vw;
    height: 13vw;
    align-self: center;
    border-radius: 100%;
    background: radial-gradient(closest-side, rgba(185, 205, 212, 0.9), rgba(135, 182, 201,0.6), rgba(92, 148, 170, 0.1));
    overflow: hidden;
}

.DSMPHomeButtonSD{
    border: 2px solid black;
    width: 2vw;
    height: 2vw;
    align-self: center;
    margin-top: 1vw;
    border-radius: 100%;
    background-color: rgb(68, 63, 63);
    overflow: hidden;
}

.DSMPS_Time{
    align-self: center;
    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-size: 2vw;
    text-shadow: 2px 2px #666666;
    color: white;
    overflow: hidden;
}

.DSMPS_google{
    background-color: aliceblue;
    width: 90%;
    align-self: center;
    border-radius: 10px;
    margin-top: 9vw;
    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-size: 1vw;
    padding-left: 0.5vw;
    color: blue;
}

.DSMPS_Apps{
    height: 3vw;
    margin-top: 0.3vw;
    display: flex;
    justify-content: center;
}

.DSMPSA1{
    background: linear-gradient(180deg, 
    rgb(144, 250, 102) 0%, 
    rgb(170, 252, 138) 20%, 
    rgb(144, 250, 102) 40%, 
    rgb(73, 126, 52) 100%);
    width: 2vw;
    height: 2vw;
    border-radius: 0.5vw;
    margin-top: 0.5vw;
    box-shadow: 0.1vw 0.1vw 0.2vw rgb(44, 42, 42);
}

.DSMPSA2{
    background: linear-gradient(180deg, 
    rgb(224, 224, 224) 0%, 
    rgb(255, 255, 255) 20%, 
    rgb(224, 224, 224) 40%, 
    rgb(156, 156, 156) 100%);
    width: 2vw;
    height: 2vw;
    border-radius: 0.5vw;
    margin-top: 0.5vw;
    margin-left: 0.5vw;
    box-shadow: 0.1vw 0.1vw 0.2vw rgb(44, 42, 42);
}

.DSMPSA3{
    background: linear-gradient(180deg, 
    rgb(224, 224, 224) 0%, 
    rgb(255, 255, 255) 20%, 
    rgb(224, 224, 224) 40%, 
    rgb(156, 156, 156) 100%);
    width: 2vw;
    height: 2vw;
    border-radius: 0.5vw;
    margin-top: 0.5vw;
    margin-left: 0.5vw;
    box-shadow: 0.1vw 0.1vw 0.2vw rgb(44, 42, 42);
}

.DSMPSA4{
    background: linear-gradient(180deg, 
    rgb(102, 191, 250) 0%, 
    rgb(149, 211, 253) 20%, 
    rgb(102, 191, 250) 40%, 
    rgb(49, 95, 126) 100%);
    width: 2vw;
    height: 2vw;
    border-radius: 0.5vw;
    margin-top: 0.5vw;
    margin-left: 0.5vw;
    box-shadow: 0.1vw 0.1vw 0.2vw rgb(44, 42, 42);
    overflow: hidden;
}

.Element.gone{
    position: absolute;
    margin-left: -5000vw;
    overflow: hidden;
}

.Element.gone.left{
    position: absolute;
    margin-left: -1000vw;
    transition:8s;
    overflow: hidden;
}

.Element.gone.right{
    position: absolute;
    margin-left: 200vw;
    transition: 6s;
    overflow: hidden;
}

.Element.gone2{
    position: absolute;
    margin-left: -200vw;
    overflow: hidden;
}

.SingPic{
    width: 100%;
    height: 115%;
    background-image: url('../assets/SingProfil.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    overflow: hidden;
}

.KussiPic{
    width: 100%;
    height: 115%;
    background-image: url('../assets/KussiProfil.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    overflow: hidden;
}
.KussiPicDefault{
    animation: KussiLoad 40s infinite; 
    overflow: hidden;
}

@keyframes KussiLoad{
    0%{width: 0%; height: 0%; background-color: rgba(172, 255, 47, 0.692);}
    1%{width: 0%; height: 100%;}
    40%{width: 80%; height: 100%;background-color: rgba(172, 255, 47, 0.692);}
    50%{width: 100%; height: 100%;background-color: rgba(255, 47, 47, 0.692);}
    60%{width: 80%; height: 100%;background-color: rgba(172, 255, 47, 0.692);}
    99%{width: 0%; height: 100%;}
    100%{width: 0%; height: 0%;background-color: rgba(172, 255, 47, 0.692);}
}

.Rotate1DefaultDeko{
    position: absolute;
    border: 8px dashed rgb(255, 224, 85);
    width: 16.5vw;
    height: 16.5vw;
    align-self: center;
    border-radius: 100%;   
    animation: Rot1 150s infinite;  
    overflow: hidden;  
    margin-top: -6vh; 
}

.Rotate2DefaultDeko{
    position: absolute;
    border: 6px dashed rgb(255, 224, 85);
    width: 13.8vw;
    height: 13.8vw;
    align-self: center;
    border-radius: 100%;   
    animation: Rot2 180s infinite;     
    overflow: hidden;
    margin-top: -6vh;
    
}


.Rotate1SD{
    position: absolute;
    margin-left: -200vw;
    overflow: hidden;
}

.Rotate2SD{
    position: absolute;
    margin-left: -200vw;
    overflow: hidden;
}

.EckeLO {
    border: 0.3vw solid rgb(255, 242, 124);
    width: 15vw;
    height: 15vw;
    position: absolute;
    top: 0;
    left: 0;
    border-bottom-style: none;
    border-right-style: none;
    margin: 1vw 1vw 1vw 1vw;
    box-shadow: -0.5vw -0.5vw 0.5vw  rgb(255, 241, 163);
    border-radius: 0.5vw;
    overflow: hidden;
}

.EckeRO {
    border: 0.3vw solid rgb(255, 242, 124);
    width: 15vw;
    height: 15vw;
    position: absolute;
    top: 0;
    right: 0;
    border-bottom-style: none;
    border-left-style: none;
    margin: 1vw 1vw 1vw 1vw;
    box-shadow: 0.5vw -0.5vw 0.5vw rgb(255, 241, 163);
    border-radius: 0.5vw;
    overflow: hidden;
}

.EckeLU {
    border: 0.3vw solid rgb(255, 242, 124);
    width: 15vw;
    height: 15vw;
    position: absolute;
    bottom: 0;
    left: 0;
    border-top-style: none;
    border-right-style: none;
    margin: 1vw 1vw 1vw 1vw;
    box-shadow: -0.5vw 0.5vw 0.5vw  rgb(255, 241, 163);
    border-radius: 0.5vw;
    overflow: hidden;
}

.EckeRU {
    border: 0.3vw solid rgb(255, 242, 124);
    width: 20vw;
    height: 20vw;
    position: absolute;
    bottom: 0;
    right: 0;
    border-top-style: none;
    border-left-style: none;
    margin: 1vw 1vw 1vw 1vw;
    box-shadow: 0.5vw 0.5vw 0.5vw rgb(255, 241, 163);
    border-radius: 0.5vw;
    overflow: hidden;
}

.Ecke.gone{
    position: absolute;
    margin-left: -200vw;
    overflow: hidden;
}

.TestSpeaker_View{
    position: fixed;
    background-image: url('../assets/Speaker_silent.png');
    width: 25%;
    height: 40%;
    margin-top: 28%;
    margin-left: 5%;
    background-size: contain;
    background-repeat: no-repeat;
    z-index: 999;
    transition: 3.5s;
}

.TestSpeakerDefault_View{
    position: fixed;
    background-image: url('../assets/Speaker.png');
    width: 10%;
    height: 10%;
    margin-top: 40%;
    margin-left: 5%;
    background-size: contain;
    background-repeat: no-repeat;
    z-index: 999;
    transition: 3.5s;
}

.TestSpeaker_Aktiv{
    position: fixed;
    background-image: url('../assets/Speaker_working.png');
    width: 25%;
    height: 40%;
    margin-top: 28%;
    margin-left: 5%;
    background-size: contain;
    background-repeat: no-repeat;
    z-index: 999;
}

.TestSpeaker2_Aktiv{
    position: fixed;
    background-image: url('../assets/Speaker2_working.png');
    width: 10%;
    height: 10%;
    margin-top: 40%;
    margin-left: 5%;
    background-size: contain;
    background-repeat: no-repeat;
    z-index: 999;
}

.TestSpeaker_gone{
    position: fixed;
    background-image: url('../assets/Speaker_silent.png');
    width: 25%;
    height: 40%;
    margin-top: 28%;
    margin-left: -1000vw;
    background-size: contain;
    background-repeat: no-repeat;
    z-index: 999;
    transition: 1s;
}

