@font-face {
    font-family: 'fibel_nordregular';
    src: url('../assets/fonts/fibel_nord-webfont.woff2') format('woff2'), url('../assets/fonts/fibel_nord-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'ar_blancaregular';
    src: url('../assets/fonts/arblanca-webfont.woff2') format('woff2'), url('../assets/fonts/arblanca-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}


.AiDe{
    height: 100%;
    width: 100%;
    overflow: hidden;
    overflow-y: hidden;
}


.AideL_Wrapper {
    
    width: 100%;
    height: 100%;
    display: flex;
    background-image: linear-gradient(#163538,#111111, #111111, #101010, #080808, #101010, #111111, #163538);
    overflow: hidden;
}


.datenseite.sichtbar{
   width: 100vw;
   height: 100vw;
   position: absolute;
   display: flex;
   justify-content: center;
   overflow: hidden;
}

.datenseite.gone{
    position: absolute;
    margin-left: -500vw;
    margin-bottom: 1vh;
    overflow: hidden;
 }

.DatenFeld{
    width: 50vw;
    height: 75vh;
    margin-top: 15vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
}
.LehrerCodeFeldSD{
    text-align: center;
    font-size: 4.0vw;
    font-weight: 500;
    height: 20vh;
    font-family: 'fibel_nordregular', sans-serif;
    overflow: hidden;
}

.LehrerCodeFeldDefault{
    text-align: center;
    font-size: 3.0vw;
    font-weight: 500;
    height: 20vh;
    font-family: Arial, Helvetica, sans-serif;
    overflow: hidden;
    color: white;
}

.SchulerCodeFeld{
    align-self: center;
    font-size: 3.0vw;
    font-weight: 500;
    font-family: 'fibel_nordregular', sans-serif;
    text-align: center;
    background: transparent;
    margin-top: 3vh;
    width: 42vw;
    border: 3px solid rgb(77, 71, 71);
}

.SchulerCodeFeld.deaktiviert{
    pointer-events: none;
    border: none;
}

.SchulerCodeFeld.error{
    color: red;
}

.ZufallsCodeButton{
    font-family: 'fibel_nordregular', sans-serif;
    font-size: 2.0vw;
    font-weight: 500;
    margin-top: 1vh;
    width: 25vw;
    height: 15vh;
    text-align: center;
    align-self: center;
    color: rgb(82, 104, 103);
    box-shadow: 0.0vw 0.0vw 0.2vw #9ecaed;
    border: 3px solid rgb(77, 71, 71);
    background-color: rgb(225, 228, 58);
    overflow: hidden;
}

.ZufallsCodeButton.gone{
    position: absolute;
    margin-left: -500%;
}

.SchulerKlassenFeldSD{
    align-self: center;
    font-family: 'fibel_nordregular', sans-serif;
    font-size: 2.5vw;
    font-weight: 500;
    text-align: center;
    background: transparent;
    margin-top: 5vh;
    height: 14vh;
    width: 42vw;
    color: rgb(82, 104, 103);
    display: flex;
    justify-content: center;
    overflow: hidden;
}

.SchulerKlassenFeldDefault{
    align-self: center;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 2.0vw;
    font-weight: 500;
    text-align: center;
    background: transparent;
    margin-top: 5vh;
    height: 14vh;
    width: 42vw;
    color: rgb(255, 255, 255);
    display: flex;
    justify-content: center;
    overflow: hidden;
}

.exaktKlasseFeld{
    align-self: center;
    font-family: 'fibel_nordregular', sans-serif;
    font-size: 2.5vw;
    font-weight: 500;
    text-align: center;
    margin-left: 1vw
}

.PlusButton{
    margin-left: 2vw;
    font-family: 'fibel_nordregular', sans-serif;
    width: 3vw;
    box-shadow: 0.0vw 0.0vw 0.2vw #9ecaed;
    border: 3px solid rgb(77, 71, 71);
    color:rgb(103, 209, 147);
    overflow: hidden;
}

.MinusButton{
    margin-left: 2vw;
    font-family: 'fibel_nordregular', sans-serif;
    width: 3vw;
    box-shadow: 0.0vw 0.0vw 0.2vw #9ecaed;
    border: 3px solid rgb(77, 71, 71);
    color:rgb(209, 103, 103);
    overflow: hidden;
}

.PlusButton:hover{
    background-color: #28fc79;
}

.PlusButton.gone{
    position: absolute;
    margin-left: -500%;
}

.MinusButton:hover{
    background-color: #28fc79;
}

.MinusButton.gone{
    position: absolute;
    margin-left: -500%;
}

.TestbogenFeldSD{
    align-self: center;
    font-family: 'fibel_nordregular', sans-serif;
    font-size: 2.5vw;
    font-weight: 500;
    text-align: center;
    background: transparent;
    margin-top: 2vh;
    width: 42vw;
    height: 14vh;
    color: rgb(82, 104, 103);
    display: flex;
    justify-content: center;
    overflow: hidden;
}

.TestbogenFeldDefault{
    align-self: center;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 2.0vw;
    font-weight: 500;
    text-align: center;
    background: transparent;
    margin-top: 2vh;
    width: 42vw;
    height: 14vh;
    color: rgb(255, 255, 255);
    display: flex;
    justify-content: center;
    overflow: hidden;
}

.BasisButton{
    font-size: 0.8vw;
    font-family: 'fibel_nordregular', sans-serif;
    font-weight: 500;
    margin-left: 1.5vw;
    width: 3vw;
    border: 3px solid rgb(77, 71, 71);
    border-radius: 0.2vw;
    padding-top: 2vh;
}

.BuchstabenButton{
    font-size: 0.6vw;
    font-family: 'fibel_nordregular', sans-serif;
    font-weight: 500;
    margin-left: 0.5vw;
    width: 3vw;
    border: 3px solid rgb(77, 71, 71);
    border-radius: 0.2vw;
    padding-top: 2vh;
}

.SilbenButton{
    font-size: 0.8vw;
    font-family: 'fibel_nordregular', sans-serif;
    font-weight: 500;
    margin-left: 0.5vw;
    width: 3vw;
    border: 3px solid rgb(77, 71, 71);
    border-radius: 0.2vw;
    padding-top: 2vh;
}

.WorterButton{
    font-size: 0.8vw;
    font-family: 'fibel_nordregular', sans-serif;
    font-weight: 500;
    margin-left: 0.5vw;
    width: 3vw;
    border: 3px solid rgb(77, 71, 71);
    border-radius: 0.2vw;
    padding-top: 2vh;
}

.WorterButton:hover{
    background-color: #28fc79;
}

.SilbenButton:hover{
    background-color: #28fc79;
}

.BuchstabenButton:hover{
    background-color: #28fc79;
}

.BasisButton:hover{
    background-color: #28fc79;
}

.HandigkeitFeldSD{
    align-self: center;
    font-family: 'fibel_nordregular', sans-serif;
    font-size: 2.5vw;
    font-weight: 500;
    text-align: center;
    background: transparent;
    margin-top: 2vh;
    height: 16vh;
    width: 42vw;
    color: rgb(82, 104, 103);
    display: flex;
    justify-content: center;
    overflow: hidden;
}

.HandigkeitFeldDefault{
    align-self: center;
    font-family: arial;
    font-size: 2.0vw;
    font-weight: 500;
    text-align: center;
    background: transparent;
    margin-top: 2vh;
    height: 16vh;
    width: 42vw;
    color: rgb(255, 255, 255);
    display: flex;
    justify-content: center;
    overflow: hidden;
}

.LinkshanderButtonSD{
    margin-left: 2vw;
    font-family: 'fibel_nordregular', sans-serif;
    font-size: 32.5w;
    width: 10vw;
    border: 3px solid rgb(77, 71, 71);
    color:rgb(103, 209, 147);
    overflow: hidden;
}

.LinkshanderButtonDefault{
    margin-left: 2vw;
    font-family: 'fibel_nordregular', sans-serif;
    font-size: 32.5w;
    width: 10vw;
    border: 3px solid rgb(77, 71, 71);
    color:rgb(103, 209, 147);
    overflow: hidden;
}

.RechtshanderButton.aktiv{
    margin-left: 2vw;
    font-family: 'fibel_nordregular', sans-serif;
    font-size: 2.5vw;
    width: 10vw;
    border: 3px solid rgb(77, 71, 71);
    color:rgb(103, 209, 147);
    overflow: hidden;
}

.RechtshanderButtonSD.aktiv{
    margin-left: 2vw;
    font-family: 'fibel_nordregular', sans-serif;
    font-size: 2.5vw;
    width: 10vw;
    border: 3px solid rgb(77, 71, 71);
    color:rgb(103, 209, 147);
    overflow: hidden;
}

.RechtshanderButtonDefault.aktiv{
    margin-left: 2vw;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 2vw;
    width: 10vw;
    border: 3px solid rgb(207, 207, 207);
    color:rgb(255, 255, 255);
    padding-top: 3%;
    overflow: hidden;
}

.LinkshanderButtonSD.aktiv{
    margin-left: 2vw;
    width: 10vw;
    box-shadow: 0.0vw 0.0vw 0.2vw #9ecaed;
    border-radius: 0.2vw;
    color:rgb(103, 209, 147)
}

.LinkshanderButtonDefault.aktiv{
    margin-left: 2vw;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 2vw;
    width: 10vw;
    border: 3px solid rgb(207, 207, 207);
    color:rgb(255, 255, 255);
    padding-top: 3%;
    overflow: hidden;
}

.RechtshanderButtonSD.inaktiv{
    margin-left: 2vw;
    font-family: 'fibel_nordregular', sans-serif;
    font-size: 2.5vw;
    width: 10vw;
    border: 3px solid rgb(77, 71, 71);
    color:rgb(82, 104, 103);
}

.RechtshanderButtonDefault.inaktiv{
    margin-left: 2vw;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 2vw;
    width: 10vw;
    border: 3px solid rgb(117, 117, 117);
    color:rgb(128, 128, 128);
    padding-top: 3%;
    overflow: hidden;
}

.LinkshanderButtonSD.inaktiv{
    margin-left: 2vw;
    width: 10vw;
    box-shadow: 0.0vw 0.0vw 0.2vw #9ecaed;
    border-radius: 0.2vw;
    color:rgb(82, 104, 103);
}

.LinkshanderButtonDefault.inaktiv{
    margin-left: 2vw;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 2vw;
    width: 10vw;
    border: 3px solid rgb(117, 117, 117);
    color:rgb(128, 128, 128);
    padding-top: 3%;
    overflow: hidden;
}

.RechtshanderButtonSD:hover{
    background-color: #28fc79;
}

.RechtshanderButtonDefault:hover{
    background-color: #aeaeae37;
}

.LinkshanderButtonDefault:hover{
    background-color: #aeaeae37;
}

.LinkshanderButtonSD:hover{
    background-color: #28fc79;
}

.VerbleibendeTestungenFeldSD{
    align-self: center;
    font-family: 'fibel_nordregular', sans-serif;
    font-size: 1.0vw;
    font-weight: 500;
    text-align: center;
    background: transparent;
    margin-top: 5vh;
    height: 11vh;
    width: 42vw;
    color: rgb(52, 66, 66);
    display: flex;
    justify-content: center;
    overflow: hidden;
}

.VerbleibendeTestungenFeldDefault{
    align-self: center;
    font-family: arial;
    font-size: 1.0vw;
    font-weight: 500;
    text-align: center;
    background: transparent;
    margin-top: 5vh;
    height: 11vh;
    width: 42vw;
    color: rgb(255, 255, 255);
    display: flex;
    justify-content: center;
    overflow: hidden;
}

.VersionsFeldSD{
    align-self: center;
    font-family: 'fibel_nordregular', sans-serif;
    font-size: 1.0vw;
    font-weight: 500;
    text-align: center;
    background: transparent;
    margin-top: 0vh;
    width: 42vw;
    height: 8vh;
    color: rgb(52, 66, 66);
    display: flex;
    justify-content: center;
    overflow: hidden;
}

.VersionsFeldDefault{
    align-self: center;
    font-family: arial;
    font-size: 0.8vw;
    font-weight: 500;
    text-align: center;
    background: transparent;
    margin-top: 0vh;
    width: 42vw;
    height: 8vh;
    color: rgb(196, 196, 196);
    display: flex;
    justify-content: center;
    overflow: hidden;
}

.LoginButtonSD{
    position: absolute;
    font-size: 3.2vw;
    font-weight: 500;
    top:80vh;
    left: 80vw;
    width: 15vw;
    height: 13vh;
    padding-top: 2vh;
    text-align: center;
    border: 3px solid rgb(0, 0, 0);
    box-shadow: 0.1vw 0.1vw 0.5vw rgb(44, 42, 42);
    color:rgb(0, 0, 0);
    background-color: rgb(173, 252, 173);
    overflow: hidden;
}

.LoginButtonDefault{
    position: absolute;
    font-size: 3.2vw;
    font-weight: 500;
    top:80vh;
    left: 75vw;
    width: 20vw;
    height: 13vh;
    padding-top: 2vh;
    text-align: center;
    border: 3px solid rgb(255, 255, 255);
    border-radius: 21px;
    box-shadow: 0.1vw 0.1vw 0.5vw rgb(44, 42, 42);
    color:rgb(255, 255, 255);
    overflow: hidden;
}


.LoginButtonSD:hover{
    background-color: rgb(57, 250, 57);
    transition: 1s;
}

.LoginButtonDefault:hover{
    background-color: rgba(240, 240, 240, 0.237);
    transition: 1s;
}

.LoginButton.gone{
    position: absolute;
    margin-left: -500%;
    overflow: hidden;
}

.StartButton{
    position: absolute;
    font-size: 3.2vw;
    font-weight: 500;
    top:80vh;
    left: 80vw;
    width: 15vw;
    height: 13vh;
    padding-top: 2vh;
    text-align: center;
    border: 0.2vw solid rgb(204, 250, 248);
    border: 3px solid rgb(0, 0, 0);
    box-shadow: 0.1vw 0.1vw 0.5vw rgb(44, 42, 42);
    color:rgb(0, 0, 0);
    background-color: rgb(173, 252, 173);
    overflow: hidden;

}

.StartButton:hover{
    background-color: rgb(57, 250, 57);
    transition: 1s;
}

.StartButton.gone{
    position: absolute;
    margin-left: -500%;
}

.diagnoseseite.gone{
    position: absolute;
    margin-left: -500vw;
    overflow: hidden;
 }


 .diagnoseseite.sichtbar{
    width: 100vw;
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    overflow: hidden;
 }

 .TargetWrapperGroß{
     position: absolute;
     left: 0;
     width: 100vw;
     height: 100vh;
     display: flex;
     justify-content: center;
     margin-bottom: 1vh;
     overflow: hidden;
 }

 .TargetWrapperGroß.gone{
    position: absolute;
    margin-left: -200vw;
    margin-bottom: 1vh;
    overflow: hidden;
}

.StimulusWrapper{
    position: absolute;
    left:0;
    top:0;
    width: 100vw;
    height:100vh;
    display:flex;
    justify-content: center;
    margin-bottom: 1vh;
    overflow: hidden;
}

.StimulusWrapper.gone{
    position: absolute;
    margin-left: -200vw;
    margin-bottom: 1vh;
    overflow: hidden;
}

.ButtonBereich{
    position: absolute;
    width: 100vw;
    height: 28vh;
    top:70vh;
    left:0;
    display: flex;
    justify-content: center;
    z-index: 5;
    overflow: hidden;
}

.TutorWrapperTop{
    z-index: 4;
    overflow: hidden;
}


.ButtonLinks{
    position: absolute;
    left: 2vw;
    width: 30vw;
    height: 18vw;
    overflow: hidden;
    
}
.ButtonLinks.gone{
    position: absolute;
    margin-left: -200vw;
    transition: 1s;
    overflow: hidden;
}

.ButtonRechts.gone{
    position: absolute;
    margin-left: 200vw;
    transition: 1s;
    overflow: hidden;
}

.ButtonRechts{
    position: absolute;
    left: 32vw;
    width: 30vw;
    height: 18vw;
    margin-left: 35vw;
    overflow: hidden;
    
}

.TargetKleinWrapper{
    position: absolute;
    width: 20vw;
    height: 50vh;
    Left: 21vw;
    Top: 10vh;
    transform: rotateZ(-5deg);
    margin-bottom: 1vh;
    overflow: hidden;
}

.TargetKleinWrapper.gone{
    position: absolute;
    right:0;
    margin-left: 500vw;
    transition: 1s;
    overflow: hidden;
}

.WeiterButtonDefault{
    position: absolute;
    width: 20vw;
    height: 10vw;
    top: 75vh;
    left: 40vw;
    border: 3px solid rgb(255, 255, 93);
    border-radius: 15px;
    background: linear-gradient(180deg, 
    rgba(126, 124, 16, 0.1) 0%,
    rgba(207, 204, 22, 0.3) 4%,
    rgba(207, 204, 22, 0.6) 29%,
    rgba(235, 231, 23, 0.6) 45%,
    rgba(207, 204, 22, 0.6) 56%,
    rgba(207, 204, 22, 0.3) 95%,
    rgba(100, 99, 11, 0.1) 100%);
    box-shadow: 0.1vw 0.1vw 0.5vw rgb(44, 42, 42);
    z-index: 6;
    margin-bottom: 1vh;
    overflow: hidden;
}

.WeiterButtonSD{
    border: 5px solid black;
    position: absolute;
    width: 25vw;
    height: 15vw;
    top: 73vh;
    left: 38vw;
    background: linear-gradient(180deg, 
    rgb(126, 124, 16) 0%,
    rgb(207, 204, 22) 4%,
    rgb(207, 204, 22) 29%,
    rgb(235, 231, 23) 45%,
    rgb(207, 204, 22) 56%,
    rgb(207, 204, 22) 95%,
    rgb(100, 99, 11) 100%);
    box-shadow: 0.1vw 0.1vw 0.5vw rgb(44, 42, 42);
    z-index: 6;
    margin-bottom: 1vh;
    overflow: hidden;
}

.WeiterButtonIcon{
    width: 100%;
    height: 100%;
    background-image: url('../assets/Button_weiter.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    justify-self: center;
    overflow: hidden;
}

.WeiterButton.gone{
    position: absolute;
    margin-left: -200vw;
    overflow: hidden;
}

.ThemeChanger{
    position: absolute;
    color: wheat;
    width: 25vw;
    height: 25vw;
    margin-left: 77vw;
    margin-top: 3vh;
    Top:0;
    Left:0;
    z-index: 10;
    overflow: hidden;
}

.ProgressbarWrapper{
    position: absolute;
    width: 50vw;
    height: 2vw;
    margin-left: 25vw;
    margin-top: 3vh;
    border: 0.4vw solid rgb(0, 0, 0);
    box-shadow: 0.1vw 0.1vw 0.2vw rgb(34, 34, 34);
    border-radius: 0.5vw;

}

.ProgressbarWrapper2{
    position: absolute;
    width: 50%;
    height: 2vw;
    margin-left: 25%;
    margin-top: 1%;
    border: 0.4vw solid rgb(0, 0, 0);
    box-shadow: 0.1vw 0.1vw 0.2vw rgb(34, 34, 34);
    border-radius: 0.5vw;
    display: flex;
    flex-direction: row;
}


.ProgressbarFill{
    
    height: 100%;
    
}


.ProgressbarWrapper.gone{

    position: absolute;
    margin-left: -500vw;

}

.ToggleFullScreen{
    position: absolute;
    width: 25vw;
    height: 45vh;
}

.FastLoginPop{
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-color: #ffffffc9;
    box-shadow: 0.0vw 0.0vw 0.2vw #627a8e;
    display: flex;
    justify-content: center;
}

.FastLoginPop.gone{
    position: absolute;
    margin-left: -500vw;
}

.RepeatButton{
    position: absolute;
    Right: 1vw;
    Bottom: 3vh;
    border: 5px solid black;
    width: 10vw;
    height: 7vh;
    background: linear-gradient(180deg, 
    rgb(18, 71, 7) 0%,
    rgb(53, 207, 22) 2%,
    rgb(53, 207, 22) 30%,
    rgb(61, 230, 28) 40%,
    rgb(53, 207, 22) 56%,
    rgb(53, 207, 22) 98%,
    rgb(18, 71, 7) 100%);
    box-shadow: 0.1vw 0.1vw 0.5vw rgb(44, 42, 42);
    transition: 1s;
    margin-bottom: 1vh;
    text-align: center;
    justify-content: center;
    display: flex;
    font-size: 2vw;
    z-index: 999;
}

.RepeatButton.gone{
    position: absolute;
    Right: -500vw;
}

.FastLoginStart{
    height: 12%;
    width: 25%;
    border-radius: 50px;
    margin-top: 42%;
    text-align: center;
    text-justify: center;
    padding-top: 1%;
    background-color: rgb(156,244,156);
    color: black;
    font-size: 300%;
    box-shadow: 0.1vw 0.1vw 0.5vw rgb(44, 42, 42);
}

.FastLoginStart:hover{
    cursor: pointer;
}

.FastLoginLEAD{
    background-color: whitesmoke;
    height: 12%;
    width: 25%;
    border-radius: 50px;
    margin-top: 40%;
    box-shadow: 0.1vw 0.1vw 0.5vw rgb(44, 42, 42);
}

.FastLoginLEAD:hover{
    cursor: pointer;
}

.FastLoginError{
    position: absolute;
    margin-top: 1%;
    color: red;
    font-size: 500%;
    text-shadow: 1px 1px #080808;
}

.LE_StartBut{
    align-self: center;
    border: 5px solid black;
    width: 35%;
    height: 15%;
    margin-top: 5%;
    background: linear-gradient(180deg, 
    rgb(18, 71, 7) 0%,
    rgb(53, 207, 22) 2%,
    rgb(53, 207, 22) 30%,
    rgb(61, 230, 28) 40%,
    rgb(53, 207, 22) 56%,
    rgb(53, 207, 22) 98%,
    rgb(18, 71, 7) 100%);
    box-shadow: 0.1vw 0.1vw 0.5vw rgb(44, 42, 42);
    font-family: 'fibel_nordregular', sans-serif;
    text-align: center;
    font-size: 350%;
    padding-top: 1%;
}

.LE_StartBut.gone{
    position: absolute;
    margin-left: -500vw;
}

.LE_StartBut:hover{
    cursor: pointer;
}

.FastloginPwd{
    position: absolute;
    height: 12%;
    width: 25%;
    border-radius: 50px;
    margin-top: 40%;
    text-align: center;
    text-justify: center;
    padding-top: 0%;
    background-color: rgb(156,244,156);
    color: black;
    font-size: 300%;
    box-shadow: 0.1vw 0.1vw 0.5vw rgb(44, 42, 42);
}

.FastloginPwd.gone{
    position: absolute;
    margin-left: -500vw;
}

.OfflineAdminseite{
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-color: #4ff990;
    z-index: 999;
}

.OA_Datenbereich{
    height: 85vh;
}

.OA_Titel{
    height: 10vh;
    text-align: center;
    font-size: 400%;
    margin-bottom: 20vh;
    padding-top: 3vh;
}

.OA_Spalte{

    height: 15vh;
    margin-top: 2vh;
    align-content: center;
    display: flex;
    justify-content: center;
}

.OA_Input{
    height: 10vh;
    margin-top: 2vh;
    width: 25vw;
    border-radius: 20px;
    background-color: transparent;
    font-size: 200%;
    text-align: center;
}

.OA_Button{
    border: 2px solid black;
    margin-left: 5vw;
    height: 10vh;
    margin-top: 2vh;
    width: 10vw;
    text-align: center;
    font-size: 250%;
    text-align: center;
    padding-top: 1vh;
    background-color: #ecea5d;
}

.OA_Button:hover{
    cursor: pointer;
    background-color: #e8e6a0;
}


.OA_Label{
    height: 10vh;
    margin-top: 4vh;
    width: 15vw;
    border-radius: 20px;
    background-color: transparent;
    font-size: 200%;
    text-align: center;
}

.OfflineAdminseite.gone{
    margin-left: -500vw;
}

.OfflineOptionen{
    position: absolute;
    width: 20vw;
    height: 35vh;
    Top:0;
    z-index: 999;
    Right:0
}

.TicketMonitor{
    position: absolute;
    Right: 1vw;
    color: #6b6b6b;
    font-size: 1.2vw;
}

.TicketMonitor.gone{
    position: absolute;
    Right: -500vw;
}



.AbschlussSeite.gone{
    margin-left: -500vw;
}

.LE_Ergebnisbutton{
    position: absolute;
    Top: 40vh;
    Left: 0vw;
    height: 40vh;
    width: 25vw;
}

.FastLoginPop{
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-color: #ffffffa5;
    box-shadow: 0.0vw 0.0vw 0.2vw #627a8e;
    display: flex;
    justify-content: center;
}

.FastLoginPop.gone{
    position: absolute;
    margin-left: -500vw;
}

.FastLoginBackKey{
    position: absolute; 
    display: flex;
    border: 3px solid black;
    margin-left: 45vw;
    margin-top:-35vh;
    height: auto;
    width: auto;
    font-size: 2.5vw;
    text-align: center;
    background-color: aliceblue;
    justify-content: center;
    padding-left: 1vw;
    padding-right: 1vw;
}

.FastLoginBackKey:hover{
    cursor: pointer;
    background-color: rgb(146, 152, 158);
}

.FastLoginBox{
    width: 70vw;
    height: 50vh;
    background-color: #83a673d4;
    box-shadow: 0.0vw 0.0vw 0.2vw #627a8e;
    margin-top: 25vh;
    border: 3px solid black;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.FastLoginButton{
    font-size: 3.2vw;
    font-weight: 500;
    align-self: center;
    width: 15vw;
    height: 13vh;
    padding-top: 2vh;
    text-align: center;
    margin-top: 2%;
    border: 3px solid rgb(0, 0, 0);
    box-shadow: 0.1vw 0.1vw 0.5vw rgb(44, 42, 42);
    color:rgb(0, 0, 0);
    background-color: rgb(173, 252, 173);
    overflow: hidden;
}

.FastLoginButton:hover{
    cursor: pointer;
    background-color: rgb(57, 250, 57);
    transition: 1s;
}

.FastLoginKeyFeld{
    align-self: center;

    width: 90%;
    height: 35%;
    margin-top: 2%;
    display: flex;
}

.FastLoginCodeFeld{
    align-self: center;
    font-size: 300%;
}

.FastLoginKey{
    border: 3px solid black;
    margin-left: 2%;
    margin-top: 0.5%;
    height: 90%;
    width: 10%;
    font-size: 500%;
    text-align: center;
}

.FastLoginKey:hover{
    background-color: aliceblue;
}

.TicketMonitor{
    position: absolute;
    width: auto;
    height: 5vh;
    Right: 1vw;
    Top: 0vh;
    color: #6b6b6b;
    font-size: 1.8vw;
}

.TicketMonitor.gone{
    position: absolute;
    Right: -5000vw;
}

.RepeatButton{
    position: absolute;
    Right: 1vw;
    Bottom: 3vh;
    border: 5px solid black;
    width: 10vw;
    height: 7vh;
    background: linear-gradient(180deg, 
    rgb(18, 71, 7) 0%,
    rgb(53, 207, 22) 2%,
    rgb(53, 207, 22) 30%,
    rgb(61, 230, 28) 40%,
    rgb(53, 207, 22) 56%,
    rgb(53, 207, 22) 98%,
    rgb(18, 71, 7) 100%);
    box-shadow: 0.1vw 0.1vw 0.5vw rgb(44, 42, 42);
    transition: 1s;
    margin-bottom: 1vh;
    text-align: center;
    justify-content: center;
    display: flex;
    font-size: 2vw;
    z-index: 999;
}

.RepeatButton.gone{
    position: absolute;
    Right: -500vw;
}

.AdminTool{
    position: absolute;
    width: 8vw;
    height: 8vw;
    z-index: 9999;
    Right:0;
    transition: 5s;
}

.AdminToolgone{
    position: absolute;
    margin-left: -1000vw;
}

.AdminTool:hover{
    border: 1px solid black;
    transition: 5s;
}

.AdminToolPage{
    z-index: 9999;
    width: 40vw;
    height: 60vh;
    position: absolute;
    margin-left: 30vw;
    margin-top: 20vh;
    background-color: #ec725dcb;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.AdminToolPage.gone{
    position: absolute;
    margin-left: -1000vw;
    margin-top: 20vh;
    background-color: #ec725dcb;
    display: flex;
}

.ATP_Button2{
    width: 15vw;
    align-self: center;
    height: 5vh;
    font-size: 150%;
    background-color: #28fc79;
    margin-top: 8vh;
}

.ATP_Button1{
    width: 35vw;
    align-self: center;
    height: 7vh;
    font-size: 150%;
    background-color: #bcfc28;
    margin-top: 2vh;
}

.ATP_Titel{
    text-align: center;
    font-size: 180%;
}

.ATP_Info{
    text-align: center;
    font-size: 110%;
    margin-top: 1vh;
    margin-bottom: 2vh;
}

.TargetSpeaker{
    position: absolute;
    width: 100vw;
    margin-top: 15vh;
    height: 60vh;
    align-content: center;
    display: flex;
    justify-content: center;
}

.TargetSpeaker_gone{
    border: 1px solid black;
    position: absolute;
    margin-left: -5000vw;
}

.SpeakerIcon_working{
    background-image: url('../assets/Speaker_working.png');
    width: 35%;
    height: 85%;
    background-size: contain;
    background-repeat: no-repeat;
    z-index: 999;
}

.SpeakerIcon_working_right{
    background-image: url('../assets/Speaker_working.png');
    width: 25%;
    height: 65%;
    background-size: contain;
    background-repeat: no-repeat;
    right: -2%;
    position: absolute;
    z-index: 999;
}

.SpeakerIcon_silent{
    background-image: url('../assets/Speaker_silent.png');
    width: 35%;
    height: 85%;
    background-size: contain;
    background-repeat: no-repeat;
    z-index: 999;
}

.SpeakerIcon_silent_right{
    background-image: url('../assets/Speaker_silent.png');
    width: 25%;
    height: 65%;
    background-size: contain;
    background-repeat: no-repeat;
    right: -2%;
    position: absolute;
    z-index: 999;
}

.SpeakerIcon_silent_right:hover{
   cursor: pointer;
}

.SatzItemLayout{
    position: absolute;
    width: 100vw;
    height:80vh;
    margin-top: 10vh;
    display: flex;
    flex-direction: column;
}

.SatzItemLayout_gone{
    position: absolute;
    margin-left: -5000vw;
}


.SIL_Bildbereich{
    height:75%;
    display: flex;
    flex-direction: row;
}

.SIL_Textbereich{
    height: 25%;
    align-items: center;
    display: flex;
    justify-content: center;
}

.SIL_Textbereich_gone{
    position: absolute;
    margin-left: -5000vw;
}

.SIL_Textbereich_Textfeld{
    font-family: 'fibel_nordregular', sans-serif;
    font-weight: 500;
    font-size: 5vw;
    text-align: center;
    background-color: white;
    width: 90%;
    padding-top: 2%;
    padding-bottom: 2%;
    box-shadow: 0.1vw 0.1vw 0.5vw rgb(44, 42, 42);
}

.SIL_Textbereich_Textfeld_typ9{
    font-family: 'fibel_nordregular', sans-serif;
    font-weight: 500;
    font-size: 3.5vw;
    text-align: center;
    background-color: white;
    width: 90%;
    padding-top: 2%;
    padding-bottom: 2%;
    box-shadow: 0.1vw 0.1vw 0.5vw rgb(44, 42, 42);
}

.SIL_Bildbereich_BildFeld1{
    height: 90%;
    box-shadow: 0.1vw 0.1vw 0.5vw rgb(44, 42, 42);
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-items: center;
    margin-left: 20%;
    background-color: white;
    z-index: 999;
}

.SIL_Bildbereich_BildFeld2{
    height: 90%;
    box-shadow: 0.1vw 0.1vw 0.5vw rgb(44, 42, 42);
    width: 20%;
    margin-left: 2%;
    display: flex;
    background-color: white;
    justify-content: center;
    align-items: center;
    justify-items: center;
    z-index: 999;
}

.SIL_Bildbereich_BildFeld3{
    height: 90%;
    box-shadow: 0.1vw 0.1vw 0.5vw rgb(44, 42, 42);
    width: 20%;
    margin-left: 2%;
    background-color: white;
    justify-content: center;
    align-items: center;
    justify-items: center;
    display: flex;
    z-index: 999;
}

.SIL_Bildbereich_BildFeld1_gone{
    position: absolute;
    margin-left: -5000vw;
}

.SIL_Bildbereich_BildFeld2_gone{
    position: absolute;
    margin-left: -5000vw;
}

.SIL_Bildbereich_BildFeld3_gone{
    position: absolute;
    margin-left: -5000vw;
}

.MetaSeite{
    position: absolute;
    width: 100vw;
    height: 100vh;
}

.MetaSeite_gone{
    position: absolute;
    margin-left: -5000vw;
}

.MetaSeite_Seite1{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
}

.MetaSeite_Seite2{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    z-index: 9999;
}

.MetaSeite_Seite3{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
}

.MetaSeite_Seite4{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
}

.MetaSeite_Seite5{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
}

.MetaSeite_Seite1_gone{
    position: absolute;
    margin-left: -50000vw;
}

.MetaSeite_Seite2_gone{
    position: absolute;
    margin-left: -5000vw;
}

.MetaSeite_Seite3_gone{
    position: absolute;
    margin-left: -5000vw;
}

.MetaSeite_Seite4_gone{
    position: absolute;
    margin-left: -5000vw;
}

.MetaSeite_Seite5_gone{
    position: absolute;
    margin-left: -5000vw;
}

.Meta_S1_Flaggenbereich{
    width: 70%;
    height: 50%;
    margin-top: 10%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.Meta_S1_Flagge_gone{
    position: absolute;
    margin-left: -5000vw;
}
.Meta_S1_Flagge_D{
    box-shadow: 0.1vw 0.1vw 0.5vw rgb(44, 42, 42);
    width: 40%;
    height:100%;
    background-color: rgba(255, 255, 255, 0.26);
    background-image: url('../assets/img/Deutschland.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    z-index: 9999;
}

.Meta_S1_Flagge_Andere{
    box-shadow: 0.1vw 0.1vw 0.5vw rgb(44, 42, 42);
    width: 40%;
    margin-left: 20%;
    height:100%;
    background-color: rgba(255, 255, 255, 0.26);
    background-image: url('../assets/img/Andere1.png');
    background-size: 120%;
    background-repeat: no-repeat;
    z-index: 9999;

}

.Meta_S2_ReiheLinks{
    height: 80%;
    margin-top: 8%;
    width: 30%;
}

.Meta_S2_ReiheRechts{
    height: 80%;
    margin-top: 8%;
    width: 30%;
    margin-left: 5%;
}

.Meta_Seite2_Reihe{
    height: 22%;
    margin-top: 3%;
    margin-bottom: 3%;
    background-color: white;
    box-shadow: 0.1vw 0.1vw 0.5vw rgb(44, 42, 42);
    display: flex;
    flex-direction: row;
    z-index: 5;
}

.Meta_Seite2_Reihe_hover{
    cursor: pointer;
}

.Meta_Seite2_Reihe_gone{
   position: absolute;
   margin-left: -5000vw;
}

.MetaS2_R_Flag{
    border: 1px solid pink;
    width: 40%;
    margin-top: 1%;
    margin-left: 1%;
    height: 92%;
}

.MetaS2_R_Flag_GB{
    width: 40%;
    margin-top: 1%;
    margin-left: 1%;
    height: 92%;
    background-image: url('../assets/img/GB.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    z-index: 9999;
}

.MetaS2_R_Flag_Frankreich{
    width: 40%;
    margin-top: 1%;
    margin-left: 1%;
    height: 92%;
    background-image: url('../assets/img/Frankreich.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    z-index: 9999;
}

.MetaS2_R_Flag_Turkei{
    width: 40%;
    margin-top: 1%;
    margin-left: 1%;
    height: 92%;
    background-image: url('../assets/img/Turkei.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    z-index: 9999;
}

.MetaS2_R_Flag_Arabisch{
    width: 40%;
    margin-top: 1%;
    margin-left: 1%;
    height: 92%;
    background-image: url('../assets/img/Arab.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    z-index: 9999;
}

.MetaS2_R_Flag_Kurdisch{
    width: 40%;
    margin-top: 1%;
    margin-left: 1%;
    height: 92%;
    background-image: url('../assets/img/Kurdistan.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    z-index: 9999;
}

.MetaS2_R_Flag_Ukrainisch{
    width: 40%;
    margin-top: 1%;
    margin-left: 1%;
    height: 92%;
    background-image: url('../assets/img/Ukraine.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    z-index: 9999;
}

.MetaS2_R_Flag_Russisch{
    width: 40%;
    margin-top: 1%;
    margin-left: 1%;
    height: 92%;
    background-image: url('../assets/img/Russland.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    z-index: 9999;
}

.MetaS2_R_Flag_Andere{
    width: 40%;
    margin-top: 1%;
    margin-left: 1%;
    height: 92%;
    background-image: url('../assets/img/Andere2.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    z-index: 9999;
}




.MetaS2_R_Label{
    font-size: 3vw;
    font-family: 'fibel_nordregular', sans-serif;
    align-self: center;
    text-align: center;
    width: 60%;
}

.Meta_S3_Buttonbereich{
    width: 90%;
    height: 60%;
    margin-top: 10%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.Meta_S4_Buttonbereich{
    width: 90%;
    height: 60%;
    margin-top: 10%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.Meta_Seite2_Button{
    box-shadow: 0.1vw 0.1vw 0.5vw rgb(44, 42, 42);
    width: 20%;
    margin-left: 3%;
    margin-right: 3%;
    justify-content: center;
    z-index: 9999;
    background-color: rgba(255, 255, 255, 0.26);
}

.Meta_Seite2_Button_gone{
    position: absolute;
    margin-left: -5000vw;
}

.Meta_Seite4_Button{
    box-shadow: 0.1vw 0.1vw 0.5vw rgb(44, 42, 42);
    width: 20%;
    margin-left: 3%;
    margin-right: 3%;
    justify-content: center;
    z-index: 9999;
    background-color: rgba(255, 255, 255, 0.26);
}

.Meta_Seite4_Button_gone{
    position: absolute;
    margin-left: -5000vw;
}

.Meta_Seite5_Button_gone{
    position: absolute;
    margin-left: -5000vw;
}

.MetaS3_B_pic{
    height: 80%;
}

.MetaS3_B_pic0{
    height: 80%;
    background-image: url('../assets/img/Regal0.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
}

.MetaS3_B_pic1{
    height: 80%;
    background-image: url('../assets/img/Regal1.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
}

.MetaS3_B_pic2{
    height: 80%;
    background-image: url('../assets/img/Regal2.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
}

.MetaS3_B_pic3{
    height: 80%;
    background-image: url('../assets/img/Regal3.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
}

.MetaS3_B_Label{
    font-size: 3vw;
    font-family: 'fibel_nordregular', sans-serif;
    align-self: center;
    text-align: center;
    margin-top: 5%;
}

.MetaS4_B_pic{
    height: 80%;
}

.MetaS4_B_pic0{
    height: 80%;
    background-image: url('../assets/img/Uhr0.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
}

.MetaS4_B_pic1{
    height: 80%;
    background-image: url('../assets/img/Uhr1.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
}

.MetaS4_B_pic2{
    height: 80%;
    background-image: url('../assets/img/Uhr2.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
}

.MetaS4_B_pic3{
    height: 80%;
    background-image: url('../assets/img/Uhr3.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
}

.MetaS5_B_pic0{
    height: 100%;
    background-image: url('../assets/img/GenderDuck_Female.png');
    background-size: contain;
    background-repeat: no-repeat;
    margin-left: 15%;
    align-self: center;
}

.MetaS5_B_pic1{
    height: 100%;
    background-image: url('../assets/img/GenderDuck_Male.png');
    background-size: contain;
    background-repeat: no-repeat;
    margin-left: 15%;
    align-self: center;
}

.Meta_S5_Buttonbereich{
    width: 90%;
    height: 60%;
    margin-top: 10%;
    display: flex;
    flex-direction: row;
    justify-content: center;

}

.Meta_Seite5_Button{
    box-shadow: 0.1vw 0.1vw 0.5vw rgb(44, 42, 42);
    width: 40%;
    margin-left: 3%;
    margin-right: 3%;
    justify-content: center;
    z-index: 9999;
    background-color: rgba(255, 255, 255, 0.26);
}

.AbschlussSeite{
    width: 100vw;
    height: 100vh;
    z-index: 999;
    position: absolute;
    background-color:  #3f4d3d;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    justify-items: center;
    align-items: center;
}

.End_Panel{
    background-color: rgb(228, 227, 227);
    width: 40%;
    align-self: center;
    justify-self: center;
    height: 35%;
    border-radius: 5px;
    box-shadow: 0.1vw 0.1vw 0.5vw rgb(35, 35, 35);
    display: flex;
    flex-direction: column;
}

.End_Panel_Titel{
    text-align: center;
    width: 100%;
    margin-top: 2%;
    font-size: 280%;
    font-weight: 1000;
}

.End_Panel_Prgrss_Bar{
    border: 4px dashed rgb(84, 84, 84);
    width: 80%;
    align-self: center;
    justify-self: center;
    margin-top: 1%;
    height: 30%;    
}

.End_Panel_Loading{
    height:100%;
    animation: sending 5s;   
    animation-iteration-count: infinite;
    background-color: #3f4d3d;
}

@keyframes sending {
    0%{width: 0%; }
    99%{width: 100%; }
    100%{width: 0%;}
}

.End_Panel_Success{
    height:100%;
    background-color: #6fa656;
}

.End_Panel_Half{
    height:100%;
    width: 50%;
    background-color: #fff93d;
}

.End_Panel_EndButton{
    margin-top: 1%;
    width: 40%;
    align-self: center;
    justify-self: center;
    text-align: center;
    height: 20%;
    font-size: 200%;
    margin-top: 2%;
    padding-top: 0%;
    border-radius: 5px;
    background-color: #3f4d3d;
    color:white;
    box-shadow: 0.1vw 0.1vw 0.5vw rgb(35, 35, 35);
}

.End_Panel_EndButton_gone{
    position: absolute;
    margin-left: -5000vw;
}

.End_Panel_EndButton:hover{
    cursor: pointer;
    background-color: #31f727;
    transition: 1s;
}

.AudioTester{
    height: 20%;
    position: absolute;
    Top: 30%;
    Left: 0%;
    width: 20%;
    z-index: 999;
}

.Kompass_LogoFeld{
    width: 45%;
    height: 10%;
    margin-bottom: 0%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Kompass_Logo{
    background-image: url('../assets/Logo1.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height: 50vh;
}

.End_Panel_gone{
    position: absolute;
    margin-left: -5000vw;
}

.DemoPanel_gone{
    position: absolute;
    margin-left: -5000vw;
}

.DemoPanel{
    background-color: rgb(228, 227, 227);
    width: 40%;
    align-self: center;
    justify-self: center;
    height: 25%;
    border-radius: 5px;
    box-shadow: 0.1vw 0.1vw 0.5vw rgb(35, 35, 35);
    display: flex;
    flex-direction: column;
}

